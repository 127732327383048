<template>
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :objParams="objParams"
        @open="open"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import { mutateTaxTypeCode } from '@/usabilityScripts/globalMutate'

export default{
    props: ['obj'],
    components: {
        tablecustom
    },
    data(){
        return{
            form: '',
            objCard: '',
            showModal: false,
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    copy: true
                },
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        open(e){
            // Кидаємо запит на відкриття тікет
            this.eventBus.emit('findTicket', {id: e.ticketId})
        },
        mutateTaxTypeCode(e){
            return mutateTaxTypeCode(e)
        },
        searchB(e){
            this.objParams.search = e
        },
    },
    computed: {
        filteredItems: function() {
            return this.form
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.taxTitle.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>