import i18n from "@/i18n";

// Оголошення змінної "menu" і визначення функції, яка повертає масив об'єктів
export var menu = () => [
    {
        // Назва розділу
        name: "applications",
        // Іконка розділу
        icon: "ri-phone-fill",
        // Шлях до розділу
        path: '/calls/',
        // Альтернативний шлях для розділу
        to: 'calls/all',
        // Мета розділу
        meta: {
            // Назва розділу, яка отримується через глобальний об'єкт i18n
            title: () => i18n.global.t('calls_all'),
            // Права доступу для розділу
            perms: 100
        },
        // Компонент для відображення розділу
        component: () => import('@/views/calls/all'),
        // Дочірні розділи (підпункти)
        children: [
            {
                name: 'calls_all',
                path: 'all',
                to: "calls/all/",
                meta: {
                    title: () => i18n.global.t('calls_all'),
                    perms: 100,
                },
                component: () => import('@/views/calls/all.vue'),
            },
            {
                name: 'cold_calls',
                path: 'all',
                to: "calls/cold",
                meta: {
                    title: () => i18n.global.t('ColdCalls'),
                    perms: 100,
                },
                component: () => import('@/views/calls/cold/all'),
            },
            {
                name: 'triggers_calls',
                path: 'all',
                to: "calls/triggers",
                meta: {
                    title: () => i18n.global.t('triggerCall'),
                    perms: 100,
                },
                component: () => import('@/views/calls/trigger/all'),
            },
            // {
            //     name: 'old-all',
            //     path: '/oldall/',
            //     to: "oldall/all",
            //     meta: {
            //         title: () => i18n.global.t('calls_all') + ' old',
            //         perms: 100,
            //     },
            //     component: () => import('@/views/calls/old/all.vue'),
            // },
            // {
            //     name: 'new',
            //     path: 'new',
            //     to: "calls/new",
            //     meta: {
            //         title: () => i18n.global.t('new'),
            //         perms: 100,
            //     },
            //     component: () => import('@/views/calls/new.vue'),
            // },
        //     {
        //         name: 'missed',
        //         path: 'missed',
        //         to: "calls/missed",
        //         meta: {
        //             title: () => i18n.global.t('missed'),
        //             perms: 100,
        //         },
        //         component: () => import('@/views/calls/missed.vue'),
        //     },
        //     {
        //         name: 'dumps',
        //         path: 'dumps',
        //         to: "calls/dumps",
        //         meta: {
        //             title: () => i18n.global.t('dumps'),
        //             perms: 100,
        //         },
        //         component: () => import('@/views/calls/dumps.vue'),
        //     },
        //     {
        //         name: 'presentations',
        //         path: 'presentations',
        //         to: "calls/presentations",
        //         meta: {
        //             title: () => i18n.global.t('presentations'),
        //             perms: 110,
        //         },
        //         component: () => import('@/views/calls/presentations/list'),
        //     },
            {
                name: 'other',
                path: 'other',
                to: "calls/other",
                meta: {
                    title: () => i18n.global.t('other'),
                    perms: 100,
                },
                component: () => import('@/views/calls/other/other'),
            }
        ]
    },
    {
        name: "dumps",
        icon: "bx bxs-phone-call",
        path: '/dumps/',
        to: 'calls/dumps',
        meta: {
            title: () => i18n.global.t('dumps'),
            perms: 100,
        },
        component: () => import('@/views/dumps/index')
    },
    {
        name: "presentations",
        icon: "ri-skype-fill",
        path: '/presentations/',
        to: 'presentations',
        meta: {
            title: () => i18n.global.t('presentations'),
            perms: 110,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/presentations/list')
    },
    {
        name: "tasks",
        icon: "ri-honour-line",
        path: '/tasks/',
        to: 'tasks/all',
        meta: {
            title: () => i18n.global.t('tasks'),
            perms: 200,
        },
        component: () => import('@/views/tasks/all')
    },
    {
        name: "newTicketsSection",
        icon: "ri-task-line",
        path: '/newTicketsSection/',
        to: 'newTicketsSection',
        meta: {
            title: () => i18n.global.t('tickets'),
            perms: 666,
        },
        // dateNew: '2025-10-10',
        beta: true,
        component: () => import('@/views/newTicketsSection/index')
    },
    {
        name: "newTickets",
        icon: "ri-task-line",
        path: '/newtickets/',
        to: 'newtickets/all',
        meta: {
            title: () => i18n.global.t('tickets'),
            perms: 300,
        },
        component: () => import('@/views/newtickets/all')
    },
    {
        name: "development",
        icon: "bx bxl-dev-to",
        path: "/development/",
        to: "software-testing",
        meta: {
            title: () => i18n.global.t('development'),
            perms: 666
        },
        component: () => import('@/views/software-testing/index'),
        children: [
            {
                name: 'bug-reports',
                path: 'bug-reports',
                to: "software-testing/bug-reports",
                meta: {
                    title: () => i18n.global.t('BugReports'),
                    perms: 666,
                },
                component: () => import('@/views/software-testing/bug-reports/index'),
            },
            {
                name: 'software-testing',
                path: 'development/software-testing',
                to: "development/software-testing",
                meta: {
                    title: () => i18n.global.t('Testing'),
                    perms: 666,
                },
                component: () => import('@/views/software-testing/index'),
            },
            {
                name: 'todo-tasks',
                path: 'development/todo',
                to: "development/todo",
                meta: {
                    title: () => i18n.global.t('Todo'),
                    perms: 666,
                },
                component: () => import('@/views/development/todo/index'),
            },
            // {
            //     name: 'notion',
            //     path: 'notion',
            //     to: "notion",
            //     meta: {
            //         title: () => i18n.global.t('Notion'),
            //         perms: 666,
            //     },
            //     component: () => import('@/views/notion/index'),
            // },
            // {
            //     name: 'Updates',
            //     icon: "bx bx-cloud-download",
            //     path: '/changelog/',
            //     to: 'changelog',
            //     meta: {
            //         title: () => i18n.global.t('Updates'),
            //         perms: 370,
            //     },
            //     dateNew: '2023-05-05',
            //     component: () => import('@/views/changelog/list')
            // },
            // {
            //     name: 'kanban-board',
            //     icon: "bx bx-cloud-download",
            //     path: '/kanban-board/',
            //     to: 'kanban-board',
            //     meta: {
            //         title: () => i18n.global.t('Дошки'),
            //         perms: 370,
            //     },
            //     dateNew: '2023-05-05',
            //     component: () => import('@/views/changelog/list')
            // },
            {
                name: 'dev-projects',
                icon: "ri-pages-line",
                path: '/development/',
                to: 'development/projects',
                meta: {
                    title: () => i18n.global.t('navProjects'),
                    perms: 666,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/development/projects/index')
            },
            // {
            //     name: 'navProjects1',
            //     icon: "ri-pages-line",
            //     path: '/projects/',
            //     to: 'projects/all1',
            //     meta: {
            //         title: () => i18n.global.t('Documentation'),
            //         perms: 350,
            //     },
            //     dateNew: '2023-05-05',
            //     component: () => import('@/views/projects/all')
            // },
            // {
            //     name: 'navProjects2',
            //     icon: "ri-pages-line",
            //     path: '/projects/',
            //     to: 'projects/all',
            //     meta: {
            //         title: () => i18n.global.t('Налаштування'),
            //         perms: 350,
            //     },
            //     dateNew: '2023-05-05',
            //     component: () => import('@/views/projects/all')
            // },
        ]
    },
    // {
    //     name: "software-testing",
    //     icon: "ri-task-line",
    //     path: '/software-testing/',
    //     to: 'software-testing',
    //     meta: {
    //         title: () => i18n.global.t('Testing v2'),
    //         perms: 666,
    //     },
    //     component: () => import('@/views/software-testing/index'),
    //     children: [
    //         {
    //             name: 'session-testing',
    //             path: 'session-testing',
    //             to: "software-testing",
    //             meta: {
    //                 title: () => i18n.global.t('Тестування'),
    //                 perms: 666,
    //             },
    //             component: () => import('@/views/software-testing/index'),
    //         },
    //         {
    //             name: 'bug-reports',
    //             path: 'bug-reports',
    //             to: "software-testing/bug-reports",
    //             meta: {
    //                 title: () => i18n.global.t('Баг-репорти'),
    //                 perms: 666,
    //             },
    //             component: () => import('@/views/software-testing/bug-reports/index'),
    //         },
    //         // {
    //         //     name: 'bug-tracker',
    //         //     path: 'bug-tracker',
    //         //     to: "software-testing/bug-tracker",
    //         //     meta: {
    //         //         title: () => i18n.global.t('Баг-трекер'),
    //         //         perms: 666,
    //         //     },
    //         //     component: () => import('@/views/software-testing/bug-tracker/index'),
    //         // },
    //     ]
    // },
    {
        name: 'navProjects',
        icon: "ri-pages-line",
        path: '/projects/',
        to: 'projects/all',
        meta: {
            title: () => i18n.global.t('navProjects'),
            perms: 350,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/projects/all')
    },
    {
        name: "messages",
        icon: "bx bx-message-alt-detail",
        path: '/messages/',
        to: 'messages',
        meta: {
            title: () => i18n.global.t('Messages'),
            perms: 1900,
        },
        component: () => import('@/views/messages/index')
    },
    {
        name: "filemanager",
        icon: "ri-upload-cloud-2-line",
        path: '/filemanager/',
        to: 'filemanager',
        meta: {
            title: () => i18n.global.t('fileManager'),
            perms: 950,
        },
        dateNew: '2023-11-11',
        component: () => import('@/views/filemanager/index')
    },
    {
        name: "archive",
        icon: "ri-archive-fill",
        path: '/archive/',
        to: 'archive/',
        meta: {
            title: () => i18n.global.t('archive'),
            perms: 400,
        },
        component: () => import('@/views/archive/applications'),
        children: [
            {
                name: 'archiveApplic',
                path: 'applications',
                to: "archive/applications",
                meta: {
                    title: () => i18n.global.t('archiveApplic'),
                    perms: 401,
                },
                component: () => import('@/views/archive/applications'),
            },
            {
                name: 'archiveMonitoring',
                path: 'monitoring',
                to: "archive/monitoring",
                meta: {
                    title: () => i18n.global.t('archiveMonitoring'),
                    perms: 401,
                },
                component: () => import('@/views/archive/monitoring'),
            },
            {
                name: 'archiveDumps',
                path: 'dumps',
                to: "archive/dumps",
                meta: {
                    title: () => i18n.global.t('archiveDumps'),
                    perms: 401,
                },
                component: () => import('@/views/archive/dumps'),
            },
            {
                name: 'archivePresentations',
                path: 'presentations',
                to: "archive/presentations",
                meta: {
                    title: () => i18n.global.t('archivePresentations'),
                    perms: 401,
                },
                component: () => import('@/views/archive/presentations'),
            },
            {
                name: 'archiveTasks',
                path: 'tasks',
                to: "archive/tasks",
                meta: {
                    title: () => i18n.global.t('archiveTasks'),
                    perms: 402,
                },
                component: () => import('@/views/archive/tasks'),
            },
            {
                name: 'archiveTickets',
                path: 'tickets',
                to: "archive/tickets",
                meta: {
                    title: () => i18n.global.t('archiveTickets'),
                    perms: 403,
                },
                component: () => import('@/views/archive/tickets'),
            },
            {
                name: 'archiveProjects',
                path: 'projects',
                to: "archive/projects",
                meta: {
                    title: () => i18n.global.t('archiveProjects'),
                    perms: 406,
                },
                component: () => import('@/views/archive/projects'),
            },
            {
                name: 'archiveShift',
                path: 'shifts',
                to: "archive/shifts",
                meta: {
                    title: () => i18n.global.t('archiveShift'),
                    perms: 404,
                },
                component: () => import('@/views/archive/shifts'),
            },
            {
                name: 'archiveSms',
                path: 'sms',
                to: "archive/sms",
                meta: {
                    title: () => i18n.global.t('archiveSms'),
                    perms: 405,
                },
                component: () => import('@/views/archive/sms'),
            },
            {
                name: 'archiveColdCalls',
                path: 'coldCalls',
                to: "archive/coldCalls",
                meta: {
                    title: () => i18n.global.t('archiveColdCalls'),
                    perms: 401,
                },
                component: () => import('@/views/archive/coldCalls'),
            }
        ]
    },
    {
        name: 'Monitoring',
        icon: "bx bx-scan",
        path: '/monitoring/',
        to: "monitoring/",
        meta: {
            title: () => i18n.global.t('monitoringLabel'),
            perms: 2030
        },
        component: () => import('@/views/archive/projects'),
    },
    {
        name: 'shop_title',
        icon: "bx bxs-shopping-bags",
        path: '/ecommerce/',
        to: 'ecommerce/',
        meta: {
            title: () => i18n.global.t('shop_title'),
            perms: 1200,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/ecommerce/products'),
        children: [
            {
                name: 'Products',
                path: 'products',
                to: "ecommerce/products",
                meta: {
                    title: () => i18n.global.t('Products'),
                    perms: 1300,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/products'),
            },
            {
                name: 'ProductCategories',
                path: 'categories',
                to: "ecommerce/categories",
                meta: {
                    title: () => i18n.global.t('ProductCategories'),
                    perms: 1400,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/categories/list'),
            },
            {
                name: 'providers',
                path: 'providers',
                to: "ecommerce/providers",
                meta: {
                    title: () => i18n.global.t('providers'),
                    perms: 1500,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/providers/list'),
            },
            // {
            //     name: 'attributes',
            //     path: 'attributes',
            //     to: "ecommerce/attributes",
            //     meta: {
            //         title: () => i18n.global.t('attributes'),
            //         perms: 1600,
            //     },
            //     dateNew: '2023-05-05',
            //     component: () => import('@/views/ecommerce/attributes/list'),
            // },
            {
                name: 'Orders',
                path: 'orders',
                to: "ecommerce/orders",
                meta: {
                    title: () => i18n.global.t('Orders'),
                    perms: 2000,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/orders/index'),
            },
            {
                name: 'clients',
                path: 'customers',
                to: "ecommerce/customers",
                meta: {
                    title: () => i18n.global.t('clients'),
                    perms: 2000,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/customers/index'),
            },
            {
                name: 'invoices',
                path: 'invoices/all',
                to: "invoices/all",
                meta: {
                    title: () => i18n.global.t('invoices'),
                    perms: 1700,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/invoices/all'),
            },
            {
                name: 'Settings',
                path: 'settings',
                to: "ecommerce/settings",
                meta: {
                    title: () => i18n.global.t('Settings'),
                    perms: 1700,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/ecommerce/settings/index'),
            },
        ]
    },
    {
        name: "generalInfo",
        icon: "bx bxs-info-circle",
        path: '/clients/',
        to: 'clients/',
        meta: {
            title: () => i18n.global.t('generalInfo'),
            perms: 800,
        },
        component: () => import('@/views/archive/applications'),
        children: [
            {
                name: 'Prices',
                path: 'pricing',
                to: "clients/pricing",
                meta: {
                    title: () => i18n.global.t('Prices'),
                    perms: 900,
                },
                component: () => import('@/views/clients/pricing'),
            },
            {
                name: 'Knowledge_base',
                path: 'KnowledgeBase',
                to: "knowledge-base/KnowledgeBase",
                meta: {
                    title: () => i18n.global.t('Knowledge_base'),
                    perms: 801,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/knowledge-base/KnowledgeBase'),
            },
            {
                name: 'qua_and_ans',
                path: 'faq',
                to: "faq/faq",
                meta: {
                    title: () => i18n.global.t('qua_and_ans'),
                    perms: 1000,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/faq/faq'),
            },
            {
                name: 'answerLabels',
                path: 'shortcuts',
                to: "shortcuts",
                meta: {
                    title: () => i18n.global.t('answerLabels'),
                    perms: 1100,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/shortcuts/index.vue'),
            }
        ]
    },
    {
        name: 'Calendar',
        icon: "ri-calendar-todo-fill",
        path: '/Calendar/',
        to: 'Calendar',
        meta: {
            title: () => i18n.global.t('Calendar'),
            perms: 420,
        },
        component: () => import('@/views/calendar/index2')
    },
    {
        name: "note",
        icon: "bx bx-notepad",
        path: '/note/',
        to: 'note',
        meta: {
            title: () => i18n.global.t('note'),
            perms: 410,
        },
        component: () => import('@/views/note/index')
    },
    {
        name: 'Updates',
        icon: "bx bx-cloud-download",
        path: '/changelog/',
        to: 'changelog',
        meta: {
            title: () => i18n.global.t('Updates'),
            perms: 370,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/changelog/list')
    },
    {
        name: "translator",
        icon: "bx bxl-dev-to",
        path: '/translator/',
        to: 'translator',
        meta: {
            title: () => i18n.global.t('Translator'),
            perms: 2000,
        },
        dateNew: '2023-12-30',
        component: () => import('@/views/translator/translations/index'),
        children: [
            {
                name: 'translations',
                path: '/translator/',
                to: "translations",
                meta: {
                    title: () => i18n.global.t('translations'),
                    perms: 2000,
                },
                component: () => import('@/views/translator/translations/index.vue'),
            },
            {
                name: 'projectStatistics',
                path: '/translator/',
                to: "projectStatistics",
                meta: {
                    title: () => i18n.global.t('projectStatistics'),
                    perms: 2000,
                },
                component: () => import('@/views/translator/projectStatistics/index.vue'),
            }
        ]
    },
    {
        name: "docs",
        icon: "bx bxl-dev-to",
        path: '/docs/',
        to: 'docs',
        meta: {
            title: () => i18n.global.t('docs'),
            perms: 380,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/docs/index'),
        children: [
            {
                name: 'docs',
                path: '/docs/',
                to: "docs",
                meta: {
                    title: () => i18n.global.t('docs'),
                    perms: 380,
                },
                component: () => import('@/views/docs/index'),
            },
            {
                name: 'attributes',
                path: '/docs/',
                to: "attributesdocs",
                meta: {
                    title: () => i18n.global.t('attributes'),
                    perms: 380,
                },
                component: () => import('@/views/docs/attributesdocs/list'),
            },
            {
                name: 'API',
                path: '/docs/',
                to: "apidocs",
                meta: {
                    title: () => 'API',
                    perms: 380,
                },
                component: () => import('@/views/docs/apidocs/list'),
            }
        ]
    },
    {
        name: "statistics",
        icon: "bx bx-analyse",
        path: '/statistics/',
        to: 'statistics/',
        meta: {
            title: () => i18n.global.t('statistics'),
            perms: 500,
        },
        component: () => import('@/views/statistics/callcentre'),
        children: [
            {
                name: 'reviewsFromClient',
                path: 'reviews',
                to: "statistics/reviews",
                meta: {
                    title: () => i18n.global.t('Reviews'),
                    perms: 500,
                },
                component: () => import('@/views/statistics/reviews/index'),
            },
            {
                name: 'callcenterStatistics',
                path: 'callcentre',
                to: "statistics/callcentre",
                meta: {
                    title: () => i18n.global.t('callcenterStatistics'),
                    perms: 502,
                },
                component: () => import('@/views/statistics/callcentre/index'),
            },
            {
                name: 'develStatistics',
                path: 'dev',
                to: "statistics/dev",
                meta: {
                    title: () => i18n.global.t('develStatistics'),
                    perms: 503,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/statistics/dev/index'),
            },
            {
                name:  i18n.global.t('usersStatistics'),
                path: 'all',
                to: "statistics/all",
                meta: {
                    title: () => i18n.global.t('usersStatistics'),
                    perms: 504,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/statistics/all/index'),
            },
            {
                name: 'Sales',
                path: 'sales',
                to: "statistics/sales",
                meta: {
                    title: () => i18n.global.t('Sales'),
                    perms: 504,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/statistics/sales/index'),
            },
            {
                name: 'shifts',
                path: 'shifts',
                to: "statistics/shifts",
                meta: {
                    title: () => i18n.global.t('shifts'),
                    perms: 504,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/statistics/shifts/index'),
            },
            {
                name: 'timeOff',
                path: 'holidays',
                to: "statistics/holidays",
                meta: {
                    title: () => i18n.global.t('timeOff'),
                    perms: 520,
                },
                dateNew: '2023-06-05',
                component: () => import('@/views/statistics/holidays/index'),
            },
            {
                name: 'Fines',
                path: 'fines',
                to: "statistics/fines",
                meta: {
                    title: () => i18n.global.t('Fines'),
                    perms: 505,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/statistics/fines/index'),
            },
            {
                name: 'Services',
                path: 'services/all',
                to: "services/all",
                meta: {
                    title: () => i18n.global.t('Services'),
                    perms: 510,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/services/all'),
            }
        ]
    },
    {
        name: "jobs",
        icon: "bx bx-user-plus",
        path: '/jobs/',
        to: 'jobs/',
        meta: {
            title: () => i18n.global.t('Робота'),
            perms: 2000,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/jobs/candidate/index'),
        children: [
            {
                name: 'candidate',
                path: 'candidate',
                to: "jobs/candidate",
                meta: {
                    title: () => i18n.global.t('Кандидати'),
                    perms: 2000,
                },
                component: () => import('@/views/jobs/candidate/index'),
            },
        ]
    },
    {
        name: "users",
        icon: "ri-team-fill",
        path: '/users/',
        to: 'users',
        meta: {
            title: () => i18n.global.t('users'),
            perms: 700,
        },
        dateNew: '2023-05-05',
        component: () => import('@/views/users/users'),
        // children: [
        //     {
        //         name: 'users',
        //         path: 'users',
        //         to: "users/users",
        //         meta: {
        //             title: () => i18n.global.t('users'),
        //             perms: 700,
        //         },
        //         component: () => import('@/views/users/users'),
        //     },
        //     {
        //         name: 'team',
        //         path: 'team',
        //         to: "team/team",
        //         meta: {
        //             title: () => i18n.global.t('team'),
        //             perms: 620,
        //         },
        //         component: () => import('@/views/team/team'),
        //     }
        // ]
    },
    {
        name: 'Accounts',
        icon: "ri-team-fill",
        path: '/accounts/',
        to: 'accounts/',
        meta: {
            title: () => i18n.global.t('Accounts'),
            perms: 1800,
        },
        component: () => import('@/views/users/users'),
        children: [
            {
                name: 'AllAccounts',
                path: 'allAccounts',
                to: "accounts/allAccounts",
                meta: {
                    title: () => i18n.global.t('AllAccounts'),
                    perms: 1810,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/accounts/allAccounts/index'),
            },
            {
                name: 'ForRemoval',
                path: 'accountsDeleted',
                to: "accounts/accountsDeleted",
                meta: {
                    title: () => i18n.global.t('ForRemoval'),
                    perms: 1820,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/accounts/accountsDeleted/index'),
            },
            {
                name: 'Unregistered',
                path: 'unregistered',
                to: "accounts/unregistered",
                meta: {
                    title: () => i18n.global.t('Unregistered'),
                    perms: 1820,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/accounts/unregistered/index'),
            },
            {
                name: 'Checks',
                path: 'checks',
                to: "accounts/checks",
                meta: {
                    title: () => i18n.global.t('Checks'),
                    perms: 2040,
                },
                component: () => import('@/views/accounts/checks/index.vue'),
            },
        ]
    },
    // {
    //     name: "companies",
    //     icon: "ri-store-fill",
    //     path: '/companies/',
    //     to: 'companies/',
    //     meta: {
    //         title: () => i18n.global.t('Companies'),
    //         perms: 1800,
    //     },
    //     component: () => import('@/views/companies/allCompanies/index'),
    //     children: [
    //         {
    //             name: 'allCompanies',
    //             path: 'allCompanies',
    //             to: "companies/allCompanies",
    //             meta: {
    //                 title: () => i18n.global.t('AllCompanies'),
    //                 perms: 1810,
    //             },
    //             dateNew: '2023-05-05',
    //             component: () => import('@/views/companies/allCompanies/index'),
    //         },
    //         // {
    //         //     name: 'companyDeleted',
    //         //     path: 'companyDeleted',
    //         //     to: "companies/companyDeleted",
    //         //     meta: {
    //         //         title: () => i18n.global.t('ForRemoval'),
    //         //         perms: 1820,
    //         //     },
    //         //     dateNew: '2023-05-05',
    //         //     component: () => import('@/views/companies/companyDeleted/index'),
    //         // }
    //     ]
    // },
    {
        name: "registration",
        icon: "ri-team-fill",
        path: '/registrations/',
        to: 'registrations/',
        meta: {
            title: () => i18n.global.t('registration'),
            perms: 1850,
        },
        component: () => import('@/views/registrations/completed/index'),
        children: [
            {
                name: 'registrationCompleted',
                path: 'completed',
                to: "registrations/completed",
                meta: {
                    title: () => i18n.global.t('registrationCompleted'),
                    perms: 1850,
                },
                dateNew: '2023-05-05',
                component: () => import('@/views/registrations/completed/index'),
            }
        ]
    },
];