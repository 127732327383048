<template>
    <div class="">
        <b-tabs 
            v-model="this.tabIndex"
            pills 
            :nav-wrapper-class="`nav-custom nav-custom-${colorTabs}`"
        >
            <b-tab :title="$t('Comments')" active>
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <comments v-if="form.comments"  :requestId="this.specialId" :obj="this.oldComments" :client="this.client" :number="this.client.personalData.phone" @getdata="this.getdata()" :company="this.obj" :sklad="this.form.tradepoints"/>
                </div>
            </b-tab>
            <b-tab :title="$t('Payments')" v-if="obj.more.companyServer == 's-1' || obj.more.companyServer == 'online-2'">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <payments :obj="this.form.bankingPayments" :client="this.client" :license="this.form.license"/>
                </div>
            </b-tab>
            <b-tab :title="$t('Payments')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <paymentsNew :license="this.form.license" :client="this.client" :obj="this.form.banking"/>
                </div>
            </b-tab>
            <b-tab :title="$t('Employees')" v-if="obj.more.companyServer == 's-1' || obj.more.companyServer == 'online-2'">
                <div style="margin:20px 0px;">
                    <workers v-if="this.form.workers" :obj="this.form.workers"  />
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Employees')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'">
                <div style="margin:20px 0px;">
                    <workersNew v-if="this.form.workers" :obj="this.form.workers"  />
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab 
                :title="$t('Checks')" 
                v-if="this.perms[2040] === true" 
                @click="isOpenChecks = true"
            >
                <template v-if="isOpenChecks == true">
                    <div style="margin:20px 0px;">
                        <checks :client="this.client" :obj="this.form"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('Outlets')" v-if="obj.more.companyServer == 's-1' || obj.more.companyServer == 'online-2'">
                <div style="margin:20px 0px;">
                    <outlets v-if="this.form.tradepoints" :obj="this.form.tradepoints" :number="this.form.userLogin"/>
                    <div v-else class="alert alert-danger">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <!-- Таб тікетів -->
            <b-tab :title="$t('tickets')" v-if="this.client.tickets.length > 0">
                <div style="margin:20px 0px;">
                    <tickets v-if="this.client.tickets" :obj="this.client.tickets" :number="this.form.userLogin"/>
                    <div v-else class="alert alert-danger">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Outlets')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'">
                <div style="margin:20px 0px;">
                    <outletsNew 
                        v-if="this.form.tradepoints" 
                        :obj="this.form.tradepoints" 
                        :objTerminals="form.terminals" 
                        :companyId="obj.companyId" 
                        :license="form.license"
                        :workers="form.workers"
                        :devices="form.devices"
                        :apps="form.apps"
                    />
                    <div v-else class="alert alert-danger">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Story')" @click="isOpenStory = true">
                <template v-if="isOpenStory == true">
                    <div style="margin:20px 0px;">
                        <history @closeOne="closeOne" :number="this.obj.ownerPhone" :client="this.client" :company="this.obj" @close="this.$emit('close', this.obj)"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('OpenShifts')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'">
                <div style="margin:20px 0px;">
                    <openShifts 
                        v-if="this.form.openShifts" 
                        :obj="this.form.openShifts" 
                        :objTerminals="form.terminals" 
                        :objTradepoints="form.tradepoints" 
                        :objWorkers="this.form.workers" 
                    />
                    <div v-else class="alert alert-danger">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <!-- <b-tab :title="$t('devices')">
                <div style="margin:20px 0px;">
                    <devices v-if="this.obj.devices" :obj="this.obj.devices" :tt="this.obj.license.sklad"/>
                    <div v-else class="alert alert-ifno">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел Null :(</span>
                        </p>
                    </div>
                </div>
            </b-tab> -->
            <b-tab :title="$t('devices')" v-if="obj.more.companyServer == 's-1' || obj.more.companyServer == 'online-2'" @click="isOpenDevices = true">
                <template v-if="isOpenDevices == true">
                    <div style="margin:20px 0px;">
                        <devices :number="this.obj.ownerPhone" :tt="this.form.tradepoints"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('devices')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'">
                <div style="margin:20px 0px;">
                    <devicesNew v-if="this.form.devices" :devices="this.form.devices" :objTerminals="form.terminals" :objTradepoints="form.tradepoints"  />
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('apps')" v-if="obj.more.companyServer == 's-1' || obj.more.companyServer == 'online-2'" @click="isOpenApps = true">
                <template v-if="isOpenApps == true">
                    <div style="margin:20px 0px;">
                        <apps :number="this.obj.ownerPhone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('apps')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'" @click="isOpenApps = true">
                <template v-if="isOpenApps == true">
                    <div style="margin:20px 0px;">
                        <appsNew :number="this.obj.ownerPhone" :apps="this.form.apps" />
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('Taxes')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'" >
                <div style="margin:20px 0px;">
                    <taxes v-if="form.taxes" :number="this.obj.ownerPhone" :objTaxes="form.taxes"/>
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('BonusPrograms')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'" >
                <div style="margin:20px 0px;">
                    <bonus v-if="form.marketing.bonus" :objBonus="form.marketing.bonus" :companyId="obj.companyId" />
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Discounts')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'" >
                <div style="margin:20px 0px;">
                    <discount v-if="form.marketing.discount" :objDiscount="form.marketing.discount" :companyId="obj.companyId"/>
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('StockAk')" v-if="obj.more.companyServer != 's-1' && obj.more.companyServer != 'online-2'" >
                <div style="margin:20px 0px;">
                    <stock v-if="form.marketing.stock" :objStock="form.marketing.stock" :objTradepoints="this.form.tradepoints" :companyId="obj.companyId"/>
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <!-- <b-tab :title="$t('apps')" v-if="this.obj.apps != null">
                <div style="margin:20px 0px;"><apps :obj="this.obj.apps"  /></div>
            </b-tab> -->
            <b-tab :title="$t('referrals')" v-if="this.referallsData != null">
                <div style="margin:20px 0px;"><referals :client="this.client" @close="this.$emit('close')" :obj="this.referallsData"/></div>
            </b-tab>
            <b-tab v-if="this.perms['510'] == true" :title="$t('Services')" @click="isOpenServices = true">
                <template v-if="isOpenServices == true">
                    <div style="margin:20px 0px;">
                        <services :number="this.obj.ownerPhone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab v-if="this.perms['1700'] == true" :title="$t('invoices')" @click="isOpenInvoices = true">
                <template v-if="isOpenInvoices == true">
                    <div style="margin:20px 0px;">
                        <invoices :number="this.obj.ownerPhone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('settings_connects')" @click="isOpenConnects = true">
                <template v-if="isOpenConnects == true">
                    <div style="margin:20px 0px;">
                        <settings_connects :tt="this.obj.companyDataObj.tradepoints" :number="this.obj.ownerPhone" />
                    </div>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import checks from "./checks/index.vue"
import comments from './comments/index'
import payments from './payments/index'
import paymentsNew from './paymentsNew/index'
import workers from './workers/index'
import workersNew from './workersNew/index'
import outlets from './outlets/index'
import outletsNew from './outletsNew/index'
import devices from './devices/index'
import devicesNew from './devicesNew/index'
import apps from './apps/index'
import appsNew from './appsNew/index.vue'
import referals from './referals/index'
import services from './services/index'
import invoices from './invoices/index'
import settings_connects from './connects/index'
import history from './history/index'
import taxes from './taxes/index'
import openShifts from './openShifts/index'
import bonus from './marketing/bonus/index'
import discount from './marketing/discount/index'
import stock from './marketing/stock/index'
import tickets from "./tickets/index.vue"
import { Clients } from "@/API.js";
import { storeS } from '@/store'

let apiServe = new Clients();

export default({
    props: ['tab', 'obj', 'referallsData', 'colorTabs', 'client',  'specialId'],
    components: {
        checks,
        comments,
        payments,
        paymentsNew,
        workers,
        workersNew,
        outlets,
        outletsNew,
        devices,
        devicesNew,
        apps,
        appsNew,
        referals,
        services,
        invoices,
        settings_connects,
        history,
        taxes,
        openShifts,
        bonus,
        discount,
        stock,
        tickets
    },
    data() {
        return {
            oldComments: '',
            form: '',
            pay: '',
            isOpenPay: false,
            isOpenServices: false,
            isOpenInvoices: false,
            isOpenConnects: false,
            isOpenStory: false,
            isOpenDevices: false,
            isOpenApps: false,
            isOpenChecks: false,
            tabIndex: 0
        }
    },
    created(){
        this.getdata()
        console.log(this.specialId)
        if(this.tab === 3) {
            this.isOpenChecks = true
        }
        console.log(this.tab)
        this.tabIndex = this.tab
    },
    methods: {
        closeOne() {
            this.$emit("closeOne", this.obj)
        },
        getdata(){
            this.form = this.obj.companyDataObj
            console.log('companyDataObj', this.form)
            if(this.obj.more.companyServer === 's-1') { // перевіряємо сервер компанії і від цього відшовхуємося що кидати в коменти
                this.oldComments = this.obj.debugData.comments
            } else {
                this.oldComments = this.obj.companyDataObj.comments
            }
        },
        getPays() {
            apiServe.getPayClient(this.obj.user_login).then(res => {
                if(res){
                    this.pay = res.data
                    this.isOpenPay = true
                }
            })
        },
    },
    computed: {
        perms(){
            return storeS.perms
        },
        checks(){
            return storeS.checks
        },
        user() {
            return storeS.userbase
        }
    }
})
</script>
