<template>
    <!-- 
        Список вихідних/відпусток по співробітнику
        - дозволяє переглянути список відгулів
        - відкрити картку відгулe
        - та інше :)
    -->

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-блоків -->
        <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - суми часу змін та кількість змін
    -->
    <statistics 
        v-if="infoblockShow" 
        :obj="this.statistics" 
        style="margin-top:15px;" 
    />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога) та 707 (створення штрафів)
        - @create - подія, яка спрацьовує при створенні нового запиту
        - @open - подія, яка спрацьовує при відкритті картки відгулу
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        @create="create" 
        @change="change" 
        @open="open"
        :pages="objPages"
        :showpag="true"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки відгулу
       - для відкриття застосовується перевірка значення modalDialog
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про штраф
       - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
    -->
    <viewbox 
        v-if="modalDialog == true"
        @close="closeModal" 
        :obj="objCard"
        @getdata="getdata"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from '@/views/statistics/holidays/view' // картка відгулу
import statistics from './statistics.vue';
import { mutateTypeHoliday, mutateStatusHoliday , mutateReasonHoliday } from '@/usabilityScripts/globalMutate.js'
import { Holidays } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних

let apiServe = new Holidays();

export default {
    props: ['id'],
    components: { tablecustom, viewbox, statistics },
    data() {
        return {
            infoblockShow: false,
            statistics: {},
            modalDialog: false, // прапорець відображення вікна створення запиту
            objCard: "", // Об'єкт з даними запиту для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false, // true - показати кнопку фільтр, false - сховати
                page: "holidays"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                pr1: '',
                pr2: '',
                createWorkerId: '',
            },
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "holidayId",
                    align: "left",
                    status: false,
                },
                {
                    name: this.$t('name'),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('type'),
                    text: "type",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.mutateTypeHoliday(item, obj)
                },
                {
                    name: this.$t('Cause'),
                    text: "reason",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateReasonHoliday(item)
                },
                {
                    name: this.$t('Status'),
                    text: "status",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateStatusHoliday(item).name,
                    mutateClass: (item) => this.mutateStatusHoliday(item).color
                },
                {
                    name: this.$t('Comment') + " (" + this.$t('confirmation') + "/" + this.$t('Refusal') + ")",
                    text: "confirmComment",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => obj.confirmComment != null ? obj.confirmComment : obj.cancelComment
                },
                {
                    name: this.$t('date'),
                    text: "date",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => obj.toDate != '' ? obj.date + " - " + obj.toDate : item 
                },
                {
                    name: this.$t('Comment') ,
                    text: "comment",
                    align: "left",
                    status: true,
                },
            ],
        }
    },
    created(){
        // Для зручності виразхування відпусток ставимо pr1 на перший день року, а pr2 на поточний
        // this.objParams.pr1 = new Date(`${new Date().getFullYear()}-01-01`).toISOString().split('T')[0];
        // this.objParams.pr2 = new Date(`${new Date().getFullYear()}-12-31`).toISOString().split('T')[0];
        // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        create() {
            this.$emit('openvacationbox')
        },
        getdata(page){
            /**
                Отримує дані про проекти з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.createWorkerId = this.id
            this.objParams.page = page != undefined ? page : '1';

            apiServe.getHolidays(this.objParams).then(result => {
                if(result.status == 'done'){
                    console.log(">", result.data);
                    this.rows = result.data.items
                    this.objPages = result.data;

                    this.statistics = result.data.statistics
                } else {
                    this.$toast.error(this.$t('error') + ` #503`);
                }
            })
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про запит за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор запиту або об'єкт з інформацією про запит.

                Викликає API для отримання інформації про запит.
                Результат присвоюється властивості objCard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiServe.getRequest(e.holidayId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.modalDialog = true;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття запиту на вихідний'; 
                    var statusRequest = result.status;
                    var api = 'getRequest';
                    var fileRequest = 'src/views/users/view/formright/vacation/index.vue';
                    var params = e.holidayId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці modalDialog в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.modalDialog = false;
            this.getdata();
        },
        mutateTypeHoliday(e, obj) {
            /*
                Функція для застосування перекладу до типу відгулу
            */

            return mutateTypeHoliday(e, obj);
        },
        mutateStatusHoliday(e) {
            /*
                Функція для застосування перекладу до статусу заявки
            */

            return mutateStatusHoliday(e);
        },
        mutateReasonHoliday(e) {
            /*
                Функція для застосування перекладу до причини вихідного співробітника
            */

            return mutateReasonHoliday(e);
        }
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>