<template>
    <!-- модальное окно создания/отправки email -->
    <modal>
        <template v-slot:title>{{ $t('sendMessage') }}</template>
        <template v-slot:head-bottom>
            <div class="dropdown" style="grid-column-start: 2; grid-column-end: 3; grid-row-start: 1; grid-column-end: 2; justify-self: right; align-self: center;">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button type="button" class="dropdown-item" @click="developPattern()">{{ $t('AddPattern') }}</button>
                </div>
            </div>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('enterPhoneNumber') }}</h4>
                    <input type="number" class="form-control" id="contactNumber" v-model="this.form.userPhone" placeholder="380">
                </div>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('enterMail') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" v-model="this.form.userEmail" placeholder="example@gmail.com">
                </div>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('searc_inp') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" @input="search(this.filter)" v-model="this.filter" :placeholder="this.$t('searc_inp')">
                </div>
                <div class="mb-3" v-if="this.ownTextPerm === true">
                    <h4 class="fs-15">{{ $t('owntextWrite') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" v-model="this.form.text" :placeholder="this.$t('print')">
                </div>
                <!-- Додатковий блок для прямого редагування шаблону -->
                <div v-if="
                    this.perms[2000] == true && 
                    this.editForm.groupId !== ''
                ">
                    <hr>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('owntextWrite') }}</h4>
                        <select class="form-select" aria-label="Default select example" @change="this.editForm.lang === $event.target.value">
                            <option :selected="this.form.lang === 'UA'">UA</option>
                            <option :selected="this.form.lang === 'PL'">PL</option>
                            <option :selected="this.form.lang === 'RU'">RU</option>
                            <option :selected="this.form.lang === 'EN'">EN</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('owntextWrite') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="this.editForm.groupId">
                    </div>
                    <div class="mb-3">
                        <input type="text" class="form-control" id="contactNumber" v-model="this.editForm.realId" :placeholder="$t('SendPulseId')">
                    </div>
                    <button
                        v-if="this.editForm.realId != ''"
                        class="btn btn-success"
                        @click="this.editLang"
                    >
                        {{ $t('Edit') }}
                    </button>
                </div>
                <div class="mb-6" style="background-color: rgb(250, 250, 250); border-radius: 20px;max-height: auto;overflow: auto;">
                    <ul style="text-align: center;">
                        <li style="list-style-type: none; font-size: 20px;" v-for="group in filteredList" :key="group">
                          <div style="display:grid; grid-template-rows: 1fr; grid-template-columns: 1fr 1fr 1fr;">
                            <span style="display: block;grid-column-start: 2; grid-column-end: 3; font-size: 20px; grid-row-start: 1; grid-column-end: 2; justify-self: center; align-self: center;"> 
                                 {{ group.name }} 
                            </span>

                            <div class="dropdown" style="grid-column-start: 2; grid-column-end: 3; grid-row-start: 1; grid-column-end: 2; justify-self: right; align-self: center;">
                                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <button type="button" class="dropdown-item" @click="developLang(group.id)">{{ $t('AddLang') }}</button>
                                    <button type="button" class="dropdown-item" @click="deleteLang(group.id)">{{ $t('Delete') }}</button>
                                    <!-- <button type="button" class="dropdown-item" @click="openTemplate(group)" >Показать шаблон</button> -->
                                </div>
                            </div>
                          </div>
                          <div class="buttons" :style="`display: grid; grid-template-rows: 1fr`"  >
                            <div >
                                <span v-for="(item, index) in group.langs" :key="index" :style="`grid-column-start: ${index+1}; grid-column-end: ${index+2};  grid-row-start: 1; grid-row-end: 2`" >
                                    <button
                                        @click="dataAdd(item, index, group.id)"
                                        class="btn" 
                                        v-bind:class="{[btn] : index===currentIndex && group.id === currentGroup }" 
                                        :class="{[btnNegative]: index!==currentIndex || group.id !== currentGroup}"
                                    >
                                        {{ item }}
                                    </button>
                                </span>
                            </div>
                            <div> <p></p> </div> 
                            <!-- пустая строка -->
                          </div>
                          
                        </li>
                    </ul>
                </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" @click="sendEmail()">{{ $t('Send') }}</button>
        </template>
    </modal>
    <addElem @close="this.createElem = false" v-if="this.createElem === true" :id="this.id" @addPattern="addPattern" @addLang="addLang" :params="this.whatCreate"> </addElem>

    <templates 
        v-if="showTemplate"
        @close="showTemplate = false"
        :obj="objTemp"
    />

</template>

<script>
import addElem from './addElem.vue'
import modal from '@/components/modal-small' 
import autotag from '@/components/autotag'
import templates from './templatesMail/index'
import {Patterns} from '@/API.js'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new Patterns();

export default ({
    data(){
        return {
            editForm: {
                "lang": "UA",       
                "groupId": "",
                "realId": ""
            },
            showTemplate: false,
            btn: "btn-success", // кнопка активна
            btnNegative: "link-success fw-medium",//кнопка неактивна
            filter: '',
            currentIndex: null,
            currentGroup: null,
            ownTextPerm: false,
            gropus: [],
            lang: '',
            whatCreate: '',
            filteredList: [],
            sendPulseId:'',
            createLang: false,
            createPattern: false,
            createElem: false,
            name: '',
            form: {
                userPhone: '',
                userEmail: '',
                groupId: '',
                lang: '',
                text: ''
            },
            objTemp: '',
            id: ''
        }
    },
    props:['users'],
    components: { modal, autotag, Multiselect, addElem, templates },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    },
    created(){
        this.getData()
        // this.form.user_phone = this.phone
        // якщо є пошта з картки, то автоматично парсимо
        if(this.users.personalData?.email) {
            this.form.userEmail = this.users.personalData.email
        } else if(this.users?.userEmail){
            this.form.userEmail = this.users.userEmail
        } 
    },
    methods:{
        deleteLang(id){
            apiServe.deletePattern(id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Removed'))
                    this.getData()
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        search(e) {
            this.filteredList = [];
            this.gropus.forEach((el) => {
                if(el.name.toLowerCase().includes(e) == true) {
                    this.filteredList.push(el) 
                }
            })
        },
        openTemplate(e){
            if(e.name === 'Визитка/контакты'){
                this.objTemp = 'cutaway';
                this.showTemplate = true
            }
        },
        developLang(e) {
            this.whatCreate = 'lang';
            this.id = e
            this.createElem = true;
            //переключалка режима языка
        },
        developPattern() {
            this.whatCreate = 'pattern';
            this.createElem = true;
            //переключалка режима группы
        },
        dataAdd(item, index, id){
            this.form.lang = ''
            this.form.groupId = ''
            this.currentIndex = index;
            this.currentGroup = id

            if(this.currentIndex === index) {
                this.btn = "btn-success"
            } 

            this.form.lang = item
            this.form.groupId = this.editForm.groupId = id
            if(id === 22) {
                this.ownTextPerm = true
            } else {
                this.ownTextPerm = false
            }//добавление данных
        },
        sendEmail() {
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterNumber'));
                return
            }
            if (!this.form.userEmail) {
                //перевіряємо чи вказана пошта
                this.$toast.error(this.$t('EnterMail'));
                return
            }
            if (!this.form.groupId) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterGroup'));
                return
            }
            if (!this.form.lang) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterLang'));
                return
            }
            apiServe.sendEmail(this.form).then(result=>{ 
                if(result.status==='done') {
                    this.$toast.success(this.$t('success'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            }) //послать письмо
        },
        editLang() {
            apiServe.editPattern(this.editForm, this.editForm.groupId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('success'));
                }
            })
        },
        addLang(id, lang, sendPulseId) {
            let obj = {
                lang: lang,
                groupId: id,
                realId: sendPulseId,
            }
            if (!obj.lang) {
                //перевіряємо чи вказаний ленг
                this.$toast.error(this.$t('EnterLang'));
            }
            if (!obj.groupId) {
                //перевіряємо чи вказана група
                this.$toast.error(this.$t('EnterGroup'));
            }
            if (!obj.realId) {
                //перевіряємо чи вказаний ідентифікатор
                this.$toast.error(this.$t('EnterConnectionId'));
            }
            if(obj.lang && obj.groupId && obj.realId) {
                apiServe.addTemplate(obj).then(result=>{
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('added'))
                        this.createElem = false;
                        this.getData()
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }
                }) //добавить шаблон
            }
        },
        getData() {
            this.gropus = []
            this.form.userPhone = this.users.userPhone
            this.form.userEmail = this.users.userEmail;
            apiServe.getGroups().then(result=>{
                if(result.status === 'done') {
                    result.data.forEach(element => {
                        this.gropus.push(element)
                        this.filteredList = this.gropus
                    });
                }
            })
        },
        addPattern(e) {
            let obj = {
                name: e
            }
            if (!obj.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            } else {
                apiServe.addGroup(obj).then(result=>{
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('added'))
                        this.createElem = false;
                        this.getData()
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }
                }) // добавить группу
            }
        },
    }
})
</script>
