<template>
  <!-- Головний контейнер з вертикальним розташуванням -->

  <div id="layout-wrapper" layout="vertical">

    <!-- 
      Компонент навігаційної панелі 
      - @openphone - подія, на відкриття вікна телефонії
      - @call - подія-виклик за номером телефону
      - @openInvoice - подія, яка відкриває картку рахунку
      - @openProject - подія, яка відкриває картку проекту
      - @openTicket - подія, яка відкриває картку тікету
      - @openTask - подія, яка відкриває картку завдання
      - @openPresentation - подія, яка відкриває картку презентації
      - @openApplication - подія, яка відкриває картку заявки
    -->
    <NavBar 
      :fullSize="this.offAll"
      @openphone="openphone" 
      @call="call" 
      @openInvoice="openInvoice"
      @openProject="openProject" 
      @openTicket="openTicket" 
      @openTask="openTask" 
      @openPresentation="openPresentation" 
      @openApplication="openApplication"
    />

    <div v-if="this.offAll === false">
      <!-- Контейнер для меню та логотипу -->
      <div class="app-menu navbar-menu">
        <!-- Паенлю мею зліва -->
        <div class="navbar-brand-box">
          
          <!-- Логотип -->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img 
                class="d-sm-none d-md-block"
                v-if="this.fullMenuStore === false"
                src="@/assets/logo_inside_sm.png" 
                alt="" 
                height="40" 
              />
              <img 
                v-if="this.fullMenuStore === true"
                src="@/assets/logo_inside.png" 
                alt="" 
                height="45" 
              />
            </span>
            <span class="logo-lg">
              <img src="@/assets/logo_inside.png" alt="" height="45" />
            </span>
          </router-link>

          <div class="text-sm-center text-white d-none d-sm-none">
            Develop by <a href="https://t.me/golovan_maxim" target="_blank" class="fw-semibold link-light">Max</a>, <a href="https://t.me/B1sTOnFire" target="_blank" class="fw-semibold link-light">Borys</a> and <a href="https://t.me/olegkrkr" target="_blank" class="fw-semibold link-light">Oleg</a>
          </div>

          <div class="text-sm-center text-white d-none d-sm-none">
            <!-- Кредити розробників -->
            Created by <a href="https://t.me/InTheBlack" target="_blank" class="fw-semibold link-light">Yuriy</a>
          </div>

          <!-- Кнопка для ініціалізації активного меню -->
          <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover" @click="initActiveMenu">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <!-- <b class="text-white fw-bold text-center lg-login">поточна дата та час</b> -->

        <!-- Меню навігації -->
        <Menu @reloadRoute="reloadRoute"></Menu>

        <div class="border-top text-center" id="bottomContainer">
          <!-- Кнопка виходу та випадаюче меню -->
          <div class="dropdown" style="width: calc(100% - 5px);">
              <button style="width: calc(100% - 20px);" class="btn btn-light dropdown-toggle lg-login" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ $t('logout') }}
              </button>
               <!-- Випадаюче меню для виходу -->
              <button type="button" class="btn btn-light btn-icon waves-effect xs-login" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-logout-box-line"></i></button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="https://cabinet.skyservice.online/">{{ $t('myCabinet') }}</a>
              </div>
          </div>
        </div>
        
        <!-- Темний фон для бічного меню -->
        <div class="sidebar-background"></div>
      </div>
      <div class="vertical-overlay" id="overlay"></div>
    </div>
  </div>

  <!-- 
    Відображення VoIP компонента/телефонія
    - для відкриття застосовується перевірка значення showvoip
    - @close - подія, яка спрацьовує при закритті картки
    - @adminClose - подія, на закриття вікна без перерви
    - :numberClient - параметр, телефон номер для прозвону
    - :formApplic - параметри заявки
    - @openClient - подія, на відкриття картки клієнта
  -->
  <voip 
    v-if="showvoip" 
    @close="showvoip = false"
    @adminClose="this.showvoip = false" 
    :numberClient="this.number" 
    :formApplic="this.formApplic"
    @openClient = "openClient"
  />

  <!-- 
    Відображення компонента перегляду заявки
    - для відкриття застосовується перевірка значення showApplic
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :shortapplic - параметри заявки
  -->
  <viewapplic
    v-if="showApplic == true"
    @close="showApplic=false"
    @edit="editApplic"
    :shortapplic = "this.obj"
  ></viewapplic>

  <!-- 
    Відображення компонента перегляду рахунку
    - для відкриття застосовується перевірка значення showInvoice
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :obj - параметри картки рахунку
  -->
  <viewinvoice
    v-if="this.showInvoice == true"
    @close="this.showInvoice = false"
    @edit="editInv"
    :obj="this.obj"
  ></viewinvoice>

  <!-- 
    Відображення компонента перегляду тікету 
    - для відкриття застосовується перевірка значення showApplic
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :obj - параметри картки тікету
  -->
  <viewticket
    v-if="showTicket == true"
    @close="showTicket = false"
    @edit="editTicket"
    :obj="this.obj"
  ></viewticket>

  <!-- 
    Відображення компонента перегляду презентації 
    - для відкриття застосовується перевірка значення showPresentation
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :shortapplic - параметри картки презентації
  -->
  <viewpresentation
    v-if="showPresentation == true"
    @close="showPresentation = false"
    @edit="editPresentation"
    :shortapplic="this.obj"
  ></viewpresentation>

  <!-- 
    Відображення компонента перегляду проекту 
    - для відкриття застосовується перевірка значення showProject
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :obj - параметри картки проекту
  -->
  <viewproject
    v-if="showProject == true"
    @close="showProject = false"
    @edit="editProject"
    :obj="this.obj"
  ></viewproject>
  
  <!-- 
    Відображення компонента перегляду завдання
    - для відкриття застосовується перевірка значення showTask
    - @close - подія, яка спрацьовує при закритті картки
    - @edit - подія, яка вмикає режим редагування
    - :shortapplic - параметри картки завдання
  -->
  <viewtask
    v-if="showTask == true"
    @close = "showTask = false"
    @edit="editTask"
    :shortapplic="this.obj"
  ></viewtask>

  <!-- 
    Вікно на редагування заявки 
    - для відкриття застосовується перевірка значення modalRequestBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки заявки
  -->
  <createapplicBox 
    v-if="modalRequestBox"
    @close="modalRequestBox = false" 
    :perm = "this.applicPerm" 
    :obj = "this.obj"
  />

  <!-- 
    Вікно на редагування завдання 
    - для відкриття застосовується перевірка значення modalTaskBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки завдання
  -->
  <createTaskBox 
    v-if="modalTaskBox" 
    @close="modalTaskBox = false" 
    :obj="this.obj" 
    :perm="this.taskPerm"
  />

  <!-- 
    Вікно на редагування тікету 
    - для відкриття застосовується перевірка значення modalTicketBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки тікету
  -->
  <createTicketBox 
    v-if="modalTicketBox" 
    @close="modalTicketBox = false" 
    :perm="ticketPerm" 
    :obj="this.obj"
  />

  <!-- 
    Вікно на редагування проекту 
    - для відкриття застосовується перевірка значення modalProjectBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки проекту
  -->
  <createProjectBox 
    v-if="modalProjectBox" 
    @close="modalProjectBox = false"
    :editform="this.obj" 
    :newform = "projectPerm"
  />

  <!-- 
    Вікно на редагування рахунку 
    - для відкриття застосовується перевірка значення modalInvoicesBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки рахунку
  -->
  <createInvoicesBox 
    v-if="modalInvoicesBox" 
    @close="modalInvoicesBox = false" 
    :perm = "this.invPerm" 
    :obj = "this.obj" 
  />

  <!-- 
    Вікно на редагування презентації 
    - для відкриття застосовується перевірка значення modalPresentBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметри-прапорець режиму редагування
    - :obj - параметри картки презентації
  -->
  <createPresentBox 
    v-if="modalPresentBox" 
    @close="modalPresentBox = false"
    :obj="this.obj" 
    :perm="this.presentationPerm" 
  />

</template>

<script>
import createapplicBox from '../../../views/application/createapplic.vue' // картка створення заявки
import createTaskBox from '../../../views/tasks/createtask.vue' // картка створення завдання
import createTicketBox from '../../../views/newtickets/createticket.vue' // картка створення тікету
import createProjectBox from '../../../views/projects/new.vue' // картка створення проекту
import createInvoicesBox from '../../../views/invoices/createinvoices.vue' // картка створення рахунку
import createPresentBox from '../../../views/presentations/add.vue' // картка створення презентації
import router from "@/router";
import Menu from "./menu.vue"; // компонент списка меню
import NavBar from "@/components/navbar/index"; // компонент верхнього меню
import voip from "@/components/voip/index" // вікно телефонії
import { storeS } from "../../../store" // глобальне сховище даних
// import { Clients } from "../../../API";
import viewapplic from "../../../views/application/view/viewapplic.vue" // картка перегляну заявки
import viewinvoice from "../../../views/invoices/view.vue" // картка перегляну рахунку
import viewticket from "../../../views/newtickets/view/index.vue" // картка перегляну тікету
import viewpresentation from "../../../views/presentations/view/index" // картка перегляну презентації
import viewproject from "../../../views/projects/view/index" // картка перегляну проекту
import viewtask from "../../../views/tasks/view/viewtask" // картка перегляну завдання
import { webviewCheck } from '@/usabilityScripts/webViewCheck.js'

// let apiServe = new Clients()

export default {
  data(){
    return{
      offAll: false,
      formApplic:'',
      invPerm: false,
      taskPerm: false,
      projectPerm: true,
      applicPerm: false,
      ticketPerm: false,
      presentationPerm: false,
      showApplic: false,
      showInvoice: false,
      showPresentation: false,
      showProject: false,
      showTask: false,
      showTicket: false,
      obj: '',
      showvoip: false,
      number: '',
      data: '',
    }
  },
  components: { 
    Menu , 
    NavBar, 
    voip, 
    viewapplic, 
    viewinvoice, 
    viewticket, 
    viewpresentation, 
    viewproject, 
    viewtask, 
    createInvoicesBox, 
    createPresentBox, 
    createProjectBox, 
    createTaskBox, 
    createTicketBox, 
    createapplicBox
  },
  methods: {
    androidFixMono () {
      // Адаптація інсайду під камеру на Android та IOS

      // Перевірка, чи використовується веб-в'ю на Android або iOS
      if(webviewCheck() == 'android_webview' || webviewCheck() == 'ios_webview') {
        // Додавання класу для фіксації висоти бічного меню
        document.querySelector('.navbar-menu').classList.add('fixWindow')
      } else {
        if(document.querySelector('.navbar-menu')) {
          // Видалення класу для відміни фіксації висоти бічного меню
          document.querySelector('.navbar-menu').classList.remove('fixWindow')
        }
      }
    },
    reloadRoute() {
      // перезавантаження розділу, без перезавантаження сторінки

      storeS.routeKey += 1;
    },
    openInvoice(e) {
      /**
        Функція для відкриття модального вікна з інформацією про рахунок.
        * @param {Object} e - об'єкт картки рахунку
      */

      this.obj = e;
      this.modalInvoicesBox = false 
      this.showInvoice = true
    },
    openApplication(e) {
      /**
        Функція для відкриття модального вікна з інформацією про заявку.
        * @param {Object} e - об'єкт картки заявки
      */

      this.obj = e;
      this.modalRequestBox = false
      this.showApplic = true
    },
    openPresentation(e) {
      /**
        Функція для відкриття модального вікна з інформацією про презентацію.
        * @param {Object} e - об'єкт картки презентації
      */

      this.obj = e;
      this.modalPresentBox = false
      this.showPresentation = true
    },
    openProject(e) {
      /**
        Функція для відкриття модального вікна з інформацією про проект.
        * @param {Object} e - об'єкт картки проекту
      */

      this.obj = e;
      this.modalProjectBox = false
      this.showProject = true
    },
    openTask(e) {
      /**
        Функція для відкриття модального вікна з інформацією про завдання.
        * @param {Object} e - об'єкт картки завдання
      */

      this.obj = e;
      this.modalTaskBox = false
      this.showTask = true
    },
    openTicket(e) {
      /**
        Функція для відкриття модального вікна з інформацією про тікет.
        * @param {Object} e - об'єкт картки тікет
      */

      this.obj = e;
      this.modalTicketBox = false
      this.showTicket = true
    },
    editApplic(e) {
      /**
        Функція для редагування заявки
        * @param {Object} e - об'єкт картки
      */

      this.applicPerm = true;
      this.obj = e
      this.showApplic = false
      this.modalRequestBox = true
    },
    editInv(e) {
      /**
        Функція для редагування рахунку
        * @param {Object} e - об'єкт картки
      */

      this.invPerm = true;
      this.obj = e
      this.showInvoice = false
      this.modalInvoicesBox = true
    },
    editPresentation(e){
      /**
        Функція для редагування презентації
        * @param {Object} e - об'єкт картки
      */

      this.presentationPerm = true;
      this.obj = e
      this.showPresentation = false
      this.modalPresentBox = true
    },
    editProject(e) {
      /**
        Функція для редагування проект
        * @param {Object} e - об'єкт картки
      */

      this.projectPerm = true;
      this.obj = e
      this.showProject = false
      this.modalProjectBox = true
    },
    editTask(e) {
      /**
        Функція для редагування завдання
        * @param {Object} e - об'єкт картки
      */

      this.taskPerm = true;
      this.obj = e
      this.showTask = false
      this.modalTaskBox = true
    },
    editTicket(e) {
      /**
        Функція для редагування тікету
        * @param {Object} e - об'єкт картки
      */

      this.ticketPerm = true;
      this.obj = e
      this.showTicket = false
      this.modalTicketBox = true
    },
    openClient(e) {
      // Відкриття картки клієнта

      this.number = e
      this.eventBus.emit('getClient', {status: true, number: this.number})
    },
    openphone(){
      // Відкриття вікна телефонії 

      this.showvoip = !this.showvoip
    },
    call(e, form) {
      // Виклик по телефону

      // Перевірка, чи визначений ідентифікатор UA в локальному сховищі
      // Необхідно для операторів по різним країнам
      if(localStorage.getItem('UA') == undefined) {
        localStorage.setItem('UA', true)
      }

      // Призначення форми та номеру для подальшого виклику
      this.formApplic = form
      this.number = e

      // Перевірка, чи відображено вікно VoIP
      if(this.showvoip == true) {
        // Оновлення номеру в VoIP, якщо відображено
        this.eventBus.emit('updateNumber', {status: true, number: this.number})
      } else {
        // Відображення VoIP та оновлення номеру, якщо не відображено
        this.showvoip = true
        this.eventBus.emit('updateNumber', {status: true, number: this.number})
      }
    }
  },
  created() {
    // if, що вимикає sidebar
    if(
      location.pathname === "/notion" ||
      location.pathname === "/notion/create"
    ) {
      this.offAll = true
      this.$emit('changeSize', true)
    } else {
      this.offAll = false
      this.$emit('changeSize', false)
    }

    // Cлухання події actualCall для оновлення даних оператора
    this.eventBus.off('actualCall')
    this.eventBus.on('actualCall', (status) => {
      if(status.status == true) {
        this.actualCall = localStorage.getItem('actualCall')
        this.call(this.actualCall)
      }
    })
    // if(((localStorage.getItem('UA') !== null || localStorage.getItem('UA') == 'true') && (this.info.workerJob == "managercallcenter" || this.perms[2000]) && (storeS.break.statusBreak !== true) && (storeS.userbase.statusshift !== 'close'))) {
    //   this.showvoip = true
    // }
  },
  mounted() {
    // Слухання змін в localStorage та виклик події
    window.addEventListener('onstorage', (event) => {
      if(localStorage.getItem('actualCall')) {
        this.actualCall = localStorage.getItem('actualCall');
        this.call(this.actualCall)
      }
    })

    // Закриття меню при кліку на overlay
    if(document.getElementById('overlay')) {
      document.getElementById('overlay').addEventListener('click',()=>{
        document.body.classList.remove('vertical-sidebar-enable')
      })
    }

    this.androidFixMono()
  },
  computed: {
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    fullMenuStore() {
      return storeS.fullMenu
    }
  },
}


</script>

<style scoped>
.logo {
  color: white;
  font-size: 2.5rem;
  font-family: 'Poppins';
}

#bottomContainer{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  width: 100%;
  color: #525252;
  border-top: 1px solid #545454;
  position: absolute;
  bottom: 0;
  padding: 15px;
}
</style>