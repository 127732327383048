<template>

    <!-- 
        Правий блок модального вікна
        - деталі
        - тікет (якщо є)
        - учасники
        - файли
    -->

    <div class="col-lg-3" >

        <!-- Деталі картки -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">{{ $t('Details') }}</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card" >
                    <table class="table table-borderless align-middle mb-0">
                        <tbody>
                            <tr>
                                <td class="fw-medium">{{ $t('Testing') }}</td>
                                <td>#<span id="t-no">{{ form.testingId }}</span> </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">{{ $t('priority') }}</td>
                                <td>
                                    <span :class="`badge rounded-pill bg-${this.mutatepriority(form.priority).color} fs-12`" >{{ this.mutatepriority(form.priority).name }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">{{ $t('Status') }}</td>
                                <td>
                                    <span :class="`badge rounded-pill bg-${this.mutatestatus(form.status).color} fs-12`" >{{ this.mutatestatus(form.status).name }}</span>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td class="fw-medium">Версія фікс:</td>
                                <td>
                                    1.22
                                </td>
                            </tr> -->
                            <tr>
                                <td class="fw-medium">{{ $t('project') }}</td>
                                <td>
                                    {{ form.problemEnvironment }}
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">{{ $t('createDate') }}</td>
                                <td id="c-date">{{ formatDateTime(form.createDatetime) }}</td>
                            </tr>
                            <tr v-if="form.inJobDatetime != null">
                                <td class="fw-medium">{{ $t('DateofEmployment') }}</td>
                                <td id="c-date">{{ formatDateTime(form.inJobDatetime) }}</td>
                            </tr>
                            <tr v-if="form.deadlineDatetime != null || form.deadlineDatetime != ''">
                                <td class="fw-medium">{{ $t('Deadline') }}</td>
                                <td id="d-date">{{ formatDateTime(form.deadlineDatetime) }}</td>
                            </tr>
                            <!-- <tr>
                                <td class="fw-medium">Мітки</td>
                                <td class="hstack text-wrap gap-1">
                                    <span class="badge bg-info">Мітка</span>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Підрахунок по таскам -->
        <!-- <div class="card" v-if="form.fromTicket.ticketId != ''"> -->
        <div class="card">
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Тестова інформація</h4>
            </div>
            <div class="card-body" >
                <b-row>
                    <b-col lg="6 border-end-dashed" style="border-color: var(--vz-border-color)!important;">
                        <div class="card-body" >
                            <div class="d-flex justify-content-center text-center" >
                                <div >
                                    <!-- <h2 class="mt-4 text-warning fw-semibold"><span class="counter-value">{{ testInfo.waitItem }}</span></h2> -->
                                    <h2 class="mt-4 text-warning fw-semibold"><span class="counter-value">Очікує</span></h2>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="card-body" >
                            <div class="d-flex justify-content-between text-center" >
                                <div >
                                    <!-- <h2 class="mt-4 text-info fw-semibold"><span class="counter-value">{{ testInfo.executed.done }}</span></h2> -->
                                    <h2 class="mt-4 text-info fw-semibold"><span class="counter-value">Виконано</span></h2>
                                    <!-- <p class="fw-medium text-danger fw-semibold fs-14 mb-0">{{ testInfo.executed.failed }} не успішно</p> -->
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="mt-3">
                        <div class="progress animated-progress">
                            <!-- <div class="progress-bar bg-info" role="progressbar" :style="`width: ${testInfo.progressBar}%`" :aria-valuenow="testInfo.progressBar" aria-valuemin="0" aria-valuemax="100"><span class="fs-12 fw-semibold">{{ testInfo.progressBar }}%</span></div> -->
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <!-- Тікет (якщо є) -->
        <div class="card" v-if="form.ticketId != null">
        <!-- <div class="card" > -->
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">{{ $t('ticket') }}</h4>
            </div>
            <div class="card-body" >
                <ul class="list-unstyled vstack gap-3 mb-0">
                    <li class="click">
                        <div class="d-flex align-items-center" >
                            <div class=" flex-shrink-0 me-3" >
                                <!-- <span class="fs-14 badge bg-info">#{{ form.fromTicket.ticketId }}</span> -->
                            </div>
                            <div class="flex-grow-1" >
                                <!-- <h5 class="fs-14 mb-0">{{ form.fromTicket.title }}</h5> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Учасники тікету -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">{{ $t('statusInWork') }}</h4>
            </div>

            <div class="card-body" >

                <template v-if="form.inJobWorkerId"> 
                    <div class="d-flex align-items-center" >
                        <div class="avatar-xs flex-shrink-0 me-3" >
                            <div class="avatar-title bg-success rounded-circle" >
                                {{ this.getInitials(form.inJobWorkerName) }}
                            </div>
                        </div>
                        <div class="flex-grow-1" >
                            <h5 class="fs-13 mb-0">{{ form.inJobWorkerName }}</h5>
                        </div>
                        <!-- <div class="flex-shrink-0" >
                            <div class="d-flex align-items-center gap-1" >
                                <div class="dropdown" >
                                    <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a class="dropdown-item copy">
                                                <i class="ri-eye-fill text-muted me-2 align-bottom"></i>
                                                {{ $t('openProfile') }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </template>
                <template v-else>
                    <h4 class="card-title fw-semibold">{{ $t('Notfound') }}</h4>
                    <p class="card-text text-muted">{{ $t('taskForTestingisNotInWork') }}</p>
                    <!-- <button class="btn btn-success">Додати</button> -->
                </template>

            </div>
        </div>

        <!-- Файли тікету -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">{{ $t('Files') }}</h4>
            </div>
            
            <div class="card-body" >

                <template v-if="form.files && form.files.length > 0">

                    <template v-for="item in form.files" :key="item">

                        <div class="vstack gap-2 mb-2" >
                            <div class="border rounded border-dashed p-2" >
                                <div class="d-flex align-items-center" >
                                    <div class="flex-shrink-0 me-3 copy" @click="viewFile(item)" >
                                        <div class="avatar-sm" >
                                            <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24`">
                                                <i :class="this.mutateImg(item.type).img"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden copy" @click="viewFile(item)" >
                                        <h5 class="fs-13 mb-1">
                                            <a class="text-body text-truncate d-block">{{ item.name }}</a>
                                        </h5>
                                    </div>
                                    <div class="flex-shrink-0 ms-2" >
                                        <div class="d-flex gap-1" >

                                            <!-- завантажити файл на пристрій -->
                                            <button
                                                @click="downloadFile(item)"
                                                type="button" 
                                                class="btn btn-icon text-muted btn-sm fs-18"
                                            >
                                                <i class="ri-download-2-line"></i>
                                            </button>

                                            <div class="dropdown" >
                                                <button class="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ri-more-fill"></i>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <!-- <li @click="deleteFile(item)"> -->
                                                    <li @click="this.gFile = item;this.dialogShow = true;">
                                                        <a class="dropdown-item copy">
                                                            <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> {{ $t('Delete') }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </template>

                </template>
                
                <template v-else>
                    <h4 class="card-title fw-semibold">{{ $t('Notfound') }}</h4>
                    <p class="card-text text-muted">{{ $t('NofilesHaveBeenAddedTestTask') }}</p>
                    <!-- <button class="btn btn-success">Додати</button> -->
                </template>

            </div>
            
        </div>

    </div>

    <!-- модальне вікно перегляду файлу -->
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="dialogShow = true" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <!-- Модальне вікно підтвердження видалення -->
    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />
    
</template>

<script>
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import { Testing } from '@/API.js';
import { 
    mutateStatusTicket,
    mutatePriorityTicket,
    mutateImgFiles
} from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store' // глобальне сховище даних


let apiServe = new Testing();

export default {
    props: ['objCard'],
    components: {
        modallg,
        modalremove
    },
    data() {
        return {
            form: '',
            testInfo: {
                waitItem: '0',
                executed: {
                    done: '',
                    failed: ''
                },
                progressBar: ''
            },
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showImg: false, // Прапорець для відображення зображення
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: [], // для відео яке завантажували вже
            // files: [] // файли
        }
    },
    created(){
        this.getdata();
        // this.checkTestInfo()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        formatDateTime(dateTimeString){
            const months = [
                "січня", "лютого", "березня", "квітня", "травня", "червня",
                "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
            ];
            const date = new Date(dateTimeString);
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${day} ${month}, ${year} ${hours}:${minutes}`;
        },
        downloadFile(e){
            // завантажити файл на пристрій
            apiServe.getFile(e.fileId)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', e.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        saveUrlFile(e){
            // Збереження файлу в локал
            storeS.blob = e;
        },
        viewFile(e) {
            // Перегляд файлу
            console.log("e", e);
            

            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                // this.$emit('getFile', e)

                var id = e.fileId ? e.fileId : e;
                this.ifPrevimg = id == this.form.files[0].fileId ? false : true; // чи показувати стрілку на попередній док
                this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true; // чи показувати стрілку на наступний док
                
                if(storeS.blob != undefined){
                    var foundName = 'urlFrom' + this.form.testingUuid + "fileId" + id;
                    var urlCache = ''
                    var statusCache = this.urlCaches.some(function(e){ 
                        urlCache = e.url
                        return e.name == foundName;
                    });
                    if(statusCache){
                        console.log(urlCache);
                        // якщо відео є в локалстор
                        this.gFile.fileId = id;
                        this.gFile.src = urlCache;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name
                        this.showImg = true;
                    } else{
                        // якщо відео немає в локалстор - додаємо
                        apiServe.getFile(e.fileId).then(result => {

                            var blob = new Blob([result.data])
                            const url = window.URL.createObjectURL(blob);

                            let opUrl = {
                                name: 'urlFrom' + this.deadlineId + "fileId" + id,
                                url: url
                            }

                            this.urlCaches.push(opUrl)
                            this.saveUrlFile(this.urlCaches);

                            this.gFile.fileId = id;
                            this.gFile.src = url;
                            this.gFile.type = e.type;
                            this.gFile.name = e.name

                            this.showImg = true;
                            storeS.uploadPercentage = 0;

                        })
                    }

                } else {
                   apiServe.getFile(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);

                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })
                }

            } else {
                // this.$toast.error(this.$t('fileNotSupported'));
                this.downloadFile(e)
            }
        },
        deleteFile(e){
            // Видалення файлу

            apiServe.deleteFile(e.fileId).then(result => {

                if(result.status == 'done') {
                    this.form.files = result.data.files;
                    this.dialogShow = false;
                    this.$toast.success(this.$t("Removed"))
                } else {
                    
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    // sendTelegramMessage
                    var errorText = 'Помилка видалення файлу в картці на тестування'; 
                    var statusRequest = result.status;
                    var api = 'deleteFile';
                    var fileRequest = 'src/views/software-testing/view_testing/formRight/index.vue';
                    var params = {
                        id: e.fileId
                    };
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }

            })

        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateImg(e) {
            // Функція для отримання кольору та іконки файлу
            return mutateImgFiles(e)
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        // checkTestInfo(){
        //     console.log("test", this.form);
            
        //     if(this.form.checklist && this.form.checklist.length > 0) {
        //         // Підрахунок кількості виконаних пунктів
        //         this.testInfo.executed.done = this.form.checklist.filter(item => item.status != 'not_run').length

        //         // Підрахунок кількості пунктів зі статусом "failed"
        //         this.testInfo.executed.failed = this.form.checklist.filter(item => item.status == 'failed').length

        //         // Підрахунок кількості пунктів, які ще не виконані ("not_run")
        //         this.testInfo.waitItem = this.form.checklist.filter(item => item.status == 'not_run').length

        //         // Загальна кількість елементів
        //         const totalItems = this.form.checklist.length;

        //         // Прогрес бар: частка виконаних елементів (у відсотках)
        //         this.testInfo.progressBar = Math.round((this.testInfo.executed.done / totalItems) * 100);
        //     }

        // }
    }
}
</script>
