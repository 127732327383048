import { reactive } from "vue";
import JsSIP from "../jssip-3.5.7";
import { storeS } from '@/store'
import { eventB } from "@/main"
import eventBus from "../main";
import soundRing from '@/assets/music/sounds_ringing.mp3'
import soundAnswered from '@/assets/music/sounds_answered.mp3'
import soundRejected from '@/assets/music/sounds_rejected.mp3'
import soundRingBack from '@/assets/music/sounds_ringback.mp3'
import axios from 'axios';

export const storeSip = reactive({
  info: {
    sounds: {
      ringing: soundRing,
      asnwered: soundAnswered,
      rejected: soundRejected,
      ringback: soundRingBack
    }, // звуки для телефонії
    login: '', // логін
    resulttime: '', // загальний час розмови
    password: '', // пароль для акаунту
    name: '', // ім'я оператора
    connected: false, // статус телефонії
    status: 'free', //статус працівника "зайнятий" або "вільний"
    callType: '', //Вихідний (outgoing) або вхідний (incoming)
    incomingSessionVue: '', // сесія телефонії
    incomingNumber: '', // номер клієнта
    mute: false, // статус чи виключений мікрофон
    startCallTime: '', // час, коли дзвінок почався
    timer: '', // змінна для інтервалу
    numbers: [], // історія звінків
  },
})

export const telephony = () => {
  
  var audio = new Audio()
  var Session = null;
  var ua = null
  var localStream = new MediaStream(); 
  var audioView = new Audio; 
  
  var socket_sip = new JsSIP.WebSocketInterface('wss://sip.skyservice.online:8089/ws');

  function registration(storeSip, registerCallbacks) {

    const { registrationFailed, registered } = registerCallbacks
    
    var configuration = {
      uri: `sip:${storeSip.login}@sip.skyservice.online:5060`,
      password: storeSip.password,
      display_name: storeSip.name,
      realm: "sip.skyservice.online",
      authorization_user: storeSip.login,
      sockets: [socket_sip],
      session_timers_refresh_method: 'invite'
    }; //авторизуем звонильщика

    ua = new JsSIP.UA(configuration) // авторизуємося в телефонії

    localStorage.setItem('UA', true) // зберігаємо статус "зареєстровано" на випадок перезавнтаження 

    ua.start() // заводимо нашу шарманку смерті
    
    if((configuration.login || configuration.password) == '') {
      this.$toast.error('Error telephony')
      return
    } //перевіряємо наявність данних

    ua.register() // реєструємося

    ua.on('registered', registered)

    ua.on('registrationFailed', registrationFailed)
    
    ua.on('newRTCSession', function(data) { // працюємо в сокеті
      audio.volume = sessionStorage.getItem('notification')
      audio.src = storeSip.sounds.ringback
      audio.loop = true
      audio.play() // звук вхідного дзвінка

      eventB.on('fixSound', (status) => {
        if(status == true) {
          audio.pause()
        }
      }) // заглушка для фіксу звуку

      Session = data.session // записуємо cесію для муту
      if (data.originator == "local") {
        storeSip.callType = 'outgoing' // дзвінок вихідний

        Session = data.session // записуємо у вихідну
        storeSip.incomingSessionVue = data.session // записуємо сесію в сховище
        Session.connection.addEventListener('track', (event) => { // коли Астерікс поверне нам потік звуку клієнта, то ми це дізнаємося
          audio.pause()
          audioView.srcObject = event.streams[0]; // закидуємо звук в навушники
          audioView.volume = sessionStorage.getItem('call')
          eventB.off('changeVolume')
          eventB.on('changeVolume', status => {
            if(status.status == true) {
              audioView.volume = status.volume
            } // подія на динамічну зміну звуку
          })
          audioView.play();
        });
      } else {
        audio.pause()
        eventB.emit('incoming', {
            status: true // подія при вхідному
        })

        if(
          storeSip.login == 305 || 
          storeSip.login == 306 || 
          storeSip.login == 303 || 
          storeSip.login == 311 || 
          storeSip.login == 314 || 
          storeSip.login == 315
        ) {
          const telegramToken = '6316239814:AAHt5JxDpETHy6FhSNLupxg-cRXTVP62vGk';
          const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;
          const telegramChatId = '369445058'; 

          const logMessage = `
            Користувачу <b>${storeSip.name.toUpperCase()} (${storeSip.login.toUpperCase()})</b> поступив вхідний дзвінок!
          `;

          axios.post(telegramApiUrl, {
            chat_id: telegramChatId,
            text: logMessage,
            parse_mode: 'html'
          });
        }

        audio.volume = sessionStorage.getItem('notification')
        audio.src = storeSip.sounds.ringing 
        audio.currentTime = 0.0;
        audio.autoplay = true
        audio.loop = true // звук гудків
        
        storeSip.incomingSessionVue = data.session // сесію закидуємо в сховище
        storeSip.startCallTime = (String(new Date())).split(' ')[4] // визначаємо час початку дзвінка
        storeSip.incomingNumber = data.session._remote_identity._display_name // закидуємо номер клієнта
        
        eventB.emit('switchNum', {
          number: data.session._remote_identity._display_name // змінюємо номер
        })

        storeSip.callType = 'incoming' // ставимо тип дзвінка
        
        Session.on('accepted', function(data) {
          audio.pause()

          audio.loop = false
          audio.src = storeSip.sounds.soundAnswered
          audio.currentTime = 0.0;
          audio.play()

          let receivers = Session.connection.getReceivers(); // отримуємо канали
          
          if (receivers) {
            receivers.forEach(item => {
              localStream.addTrack(item.track); // закидуємо звук в навушниках
            })
            audioView.volume = sessionStorage.getItem('call')
            eventB.off('changeVolume')
            eventB.on('changeVolume', status => {
              if(status.status == true) {
                audioView.volume = status.volume // для динамічної зміни звуку
              }
            })
            audioView.srcObject = localStream;
            audioView.play();
            console.log('Пізділінькаємо')
            storeSip.status = 'busy'
          }

        })

        Session.on('failed', function(e) {
          audio.pause()
          console.log('Щось пішло не так', e)
          clearInterval(storeSip.timer) // закінчуємо таймер
          storeSip.status = 'free'
          audioView.volume = sessionStorage.getItem('notification')
          audioView.autoplay = true
          audioView.src = storeSip.sounds.rejected;

          storeSip.callType = ''
        })

        Session.on('ended', function(e) {
          console.log('Закінчили виклик')
          storeSip.mute = false // оновнлюємо статус муту
          eventB.emit('getData', {status: true}) // оновлюємо історію дзвінків
          storeSip.status = 'free'
          audio.pause()
          clearInterval(storeSip.timer) // закінчуємо таймер
          storeSip.callType = ''
          audioView.volume = sessionStorage.getItem('notification')
          audioView.autoplay = true
          audioView.src = storeSip.sounds.rejected;
          eventB.emit('statusFree', {status:true}) // змінюємо статус оператора
        });

      }

      Session.on('muted', function(data) {
        storeSip.mute = true // оновнлюємо статус муту
        console.log('Замутилися')
      })

      Session.on('unmuted', function(data) {
        storeSip.mute = false // оновнлюємо статус муту
        console.log('Розмутилися')
      })
    })
  }

  function unreg(unregisterCallBacks) {

    const {unregistered, disconnect} = unregisterCallBacks

    if(localStorage.getItem('UA')) {
      localStorage.removeItem('UA') // забороняємо автоматичну реєстрацію в телефонії після перезавантаження сторінки
    }

    let options = {
      all:true
    }

    ua.unregister(options)
    ua.stop()

    ua.on('unregistered', unregistered)

    ua.on('disconnected', disconnect)
    
  }

  function call(num, callbacks) {
    const {connecting, progress, failed, ended, confirmed, refer, reinvite, update} = callbacks
    var eventHandlers = { //события для вызова
      'connecting': connecting, 
      'progress': progress,
      'failed': failed, 
      'ended': ended, 
      'confirmed': confirmed,
    } // прописуємо події для дзвінка, перед цим, ми їх прийняли

    var optionsCall = {
      eventHandlers    : eventHandlers,
      mediaConstraints : { audio: {autoGainControl: false}, video: false }, //минусуем автонастройку микро и видео минусуем
      rtcOfferConstraints:{
          offerToReceiveAudio: 1, // Принимаем только аудио
          offerToReceiveVideo: 0
      },
      pcConfig:{
          hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
          rtcpMuxPolicy: 'negotiate', // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
          iceServers: [
              {
                  urls:[
                      'stun:stun.l.google.com:19302',
                      'stun:stun1.l.google.com:19302',
                      'stun:stun2.l.google.com:19302',
                      'stun:stun3.l.google.com:19302',
                      'stun:stun4.l.google.com:19302',
                      'stun:stun01.sipphone.com',
                      'stun:stun.services.mozilla.com'
                  ]
              }
          ]
      }
    } // налаштовуємо опції дзвінка

    Session = ua.call(num, optionsCall) // записуємо сесію
    
  }

  function hangup() {
    audio.pause()
    //скинути виклик
    ua.terminateSessions()
  }

  function Mute(Session) {
    let options = {
      audio: true,
    }
    Session.mute(options)
  } // виключити звук

  function unMute(Session, a) {
    let options = {
      audio: true,
    }
    Session.unmute(options)
  } // увімкнути звук

  function sendInfo(a){
    if(Session) {

      // Обработайте событие успешной отправки DTMF
      Session.on('succeeded', () => {
        console.log('DTMF sent successfully'); // успішно відправили
      });
    
      // Обработайте событие неудачной отправки DTMF
      Session.on('failed', (event) => {
        console.error('Failed to send DTMF:', event); // не вийшло відправити
      });

      Session.sendDTMF(a) // відправити команду
    }
  }


  function answer(a, numbers) {
    var optionsAnswer = {
      pcConfig:
      {
          hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
          rtcpMuxPolicy: 'negotiate', // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
          iceServers: [
              {
                'urls':
                [
                  'stun:stun.l.google.com:19302',
                  'stun:stun1.l.google.com:19302',
                  'stun:stun2.l.google.com:19302',
                  'stun:stun3.l.google.com:19302',
                  'stun:stun4.l.google.com:19302',
                  'stun:stun01.sipphone.com',
                  'stun:stun.services.mozilla.com'
                ]
              }
          ]
      },
      mediaConstraints:
      {
          audio: {
            autoGainControl: false //отключаем авторегулировку микрофона WebRTC
          }, // Поддерживаем только аудио
          video: false
      },
      rtcOfferConstraints:
      {
          offerToReceiveAudio: 1, // Принимаем только аудио
          offerToReceiveVideo: 0
      }
    } // налаштування для дзвінка

    a.answer(optionsAnswer); // відповісти на дзвінок
    
    var data = {
      status: true,
      number: numbers
    }
    
    eventBus.emit('getClient', data) // отримати картку клієта
  }

  return {
    registration,
    unreg,
    call,
    hangup,
    answer,
    Mute,
    unMute,
    sendInfo
  }
}
