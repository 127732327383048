<template>

    <!-- Нагадування про бонусну точку -->
    <b-alert 
        v-if="this.license.registration < '2020-01-01' && this.license.countActiveTradepoints > 1" 
        show 
        variant="danger"
    >
        Увага! До обраної компанії 2-а торгова точка може йти бонусом. 
        <br />Це архівний тариф для акаунтів в яких реєстрація до 2020.01.01.
    </b-alert>

    <infoblocks  :obj="this.blocks" style="margin-top:15px;" />

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />


    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :datafilter="filterAll"
        @change="change" 
        @clearFilter="clearFilter"
        @open="open"
        :tabs="othertabs"
        :addtabs="true"
        @addCategory="showFilterBox = true"
        @removeCategory="removeCategory"
        @changeTab="changeTab"
    />

    <!-- таби -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>

    <!-- <template v-if="showModal == true" >
        <viewpay @close="showModal = false" :shortapplic="cardApplic"/>
    </template> -->
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import filterTabs from './filterTabs/index'
import infoblocks from '@/components/info/blocks'
import { storeS } from '@/store';
// import viewpay from './view'
import { mutatePayPayments } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['obj', 'license', 'client'],
    components: { 
        tablecustom, 
        // viewpay, 
        infoblocks,
        filterTabs
    },
    data() {
        return {
            showFilterBox: false,
            othertabs: [],
            form: {},
            search: "",
            cardApplic: "",
            showModal: false,
            infoblockShow: false,
            currency: "",
            selectStatus: '0',
            newtariff: {
                oldPrice: '',
                newPrice: '',
                allDays: '',
                activeDays: '',
                newDate: ''
            },
            columns: [
                {
                    name: this.$t("paymentid"),
                    text: "paymentId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Result"),
                    text: "paymentStatus",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutatePay(item).name,
                    mutateClass: (item) => this.mutatePay(item).color
                },
                {
                    name: this.$t("Gateway"),
                    text: "paymentDescription",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("Sum"),
                    text: "paymentAmount",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => item + " " + this.currency,
                },
                {
                    name: this.$t("date_time"),
                    text: "datetime",
                    align: "right",
                    status: true,
                    copy: true
                },
            ],
            filterAll: [
                {
                    page: "outlets",
                    name: "Status",
                    category: "0",
                    items: []
                }
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
                {
                    title: this.$t('successfully'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    sum: "0"
                },
                {
                    title: this.$t('notSuccessful'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    sum: "0"
                },
            ],
            blocks: [
                {
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                },
                {
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                },
                {
                    title: this.$t('PaidUpTo'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                },
                {
                    title: this.$t('CurrentBalance'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                },
                {
                    title: this.$t('Lacks'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                },
                {
                    title: this.$t('Registered'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
            ],
            // blockOct: [
            //     {
            //         title: this.$t('SubscriptionFee') + " з 1.10",
            //         type: 'colorsBlock',
            //         value: "0",
            //         icon: "las la-file-invoice",
            //         color: "info",
            //     },
            //     {
            //         title: 'Загальний залишок днів',
            //         type: 'colorsBlock',
            //         value: "0",
            //         icon: "las la-file-invoice",
            //         color: "info",
            //     },
            //     {
            //         title: 'Скільки днів відняли',
            //         type: 'colorsBlock',
            //         value: "0",
            //         icon: "las la-file-invoice",
            //         color: "info",
            //     },
            //     {
            //         title: 'Сплачено до (З урахуванням перерахунку)',
            //         type: 'colorsBlock',
            //         value: "0",
            //         icon: "las la-file-invoice",
            //         color: "info",
            //     },
            // ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj?.reverse();
            this.currency = this.license.currency;
            let newobj = this.form
            let newArrStatus = Array.from(new Set(newobj.map(item => JSON.stringify(item.paymentStatus)))).map(item => JSON.parse(item));
            this.filterAll[0]['items'] = newArrStatus;

            this.blocks[0].value = this.license.tariffName == ('' || undefined) ? this.$t('Null')  : this.license.tariffName
            this.blocks[1].value = (Boolean(this.license.userSumm) || Boolean(this.currency)) == false ? this.$t('Null') : this.license.userSumm  + " " + this.currency
            this.blocks[2].value = Boolean(this.license.activeAccount) == false ? this.$t('Null') : this.license.activeAccount
            this.blocks[3].value = (Boolean(this.license.userBalance) || Boolean(this.currency)) == false ? this.$t('Null') : this.license.userBalance  + " " + this.currency
            this.blocks[4].value = this.license.userBalance > this.license.userSumm ? '🤘🏼' : (Boolean(this.license.userSumm - this.license.userBalance) || Boolean(this.currency))  == false ? this.$t('Null') : (this.license.userSumm - this.license.userBalance).toFixed(2) + " " + this.currency
            this.blocks[5].value = this.license.registration
            // this.blocks[5].value = this.client.personalData.register_date

            // this.newActive();
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.paymentStatus == 'success').length
            this.information[2].value = this.filteredItems.filter(item => item.paymentStatus == 'failure').length
            
            this.information[0].sum = this.arraySum(this.filteredItems).toFixed(2)
            this.information[1].sum = this.arraySum(this.filteredItems.filter(item => item.paymentStatus == 'success')).toFixed(2)
            this.information[2].sum = this.arraySum(this.filteredItems.filter(item => item.paymentStatus == 'failure')).toFixed(2)
            this.infoblockShow = true
        },
        newActive(){
            this.newtariff.oldPrice = this.license.userSumm;
            this.newtariff.newPrice = this.license.userSumm/26*40;
            var one = new Date(); 
            var two = new Date(this.license.activeAccount); 
            var m = Math.floor((one - two)/(60 * 60 * 24 * 1000));
            this.newtariff.allDays = Math.abs(m)
            this.newtariff.activeDays = Math.round(this.newtariff.allDays - (this.newtariff.allDays*(this.license.userSumm/this.newtariff.newPrice)));
            // const today = new Date("2023-09-30");
            const today = new Date();
            today.setDate(two.getDate() - this.newtariff.activeDays);

            let old = this.blocks[2].value // беремо блок старої сплати
            // console.log(old.split('-')[0], old.split('-')[1], (Number(old.split('-')[2]) - this.newtariff.activeDays))
            this.newtariff.newDate = new Date(old.split('-')[0], Number(old.split('-')[1]) - 1, Number(old.split('-')[2]) - this.newtariff.activeDays) // дата в JS форматі
            this.newtariff.newDate = this.newtariff.newDate.getFullYear() + '-' + (Number(this.newtariff.newDate.getMonth()) + 1) +'-' + this.newtariff.newDate.getDate() // дата в текстовому форматі
            
            console.log('newtariff', this.newtariff);
            this.blockOct[0].value = this.newtariff.newPrice ? this.newtariff.newPrice + ' ' + this.currency :  this.$t('Null')
            this.blockOct[1].value = this.newtariff.allDays ? this.newtariff.allDays : this.$t('Null')
            this.blockOct[2].value = this.newtariff.activeDays ? this.newtariff.activeDays : this.$t('Null')
            this.blockOct[3].value = this.newtariff.newDate ? this.newtariff.newDate : this.$t('Null')
            // this.blockOct[4].value = this.newtariff.newPrice ? this.newtariff.newPrice + ' ' + this.currency :  this.$t('Null')

        },
        arraySum(array){
            var sum = 0;
            for(var i = 0; i < array.length; i++){
                sum += Number(array[i].paymentAmount);
            }
            return sum
        },
        searchB(e){
            this.search = e
        },
        change(name, e){
            if(name == 'Status'){
                this.selectStatus = e;
            }
        },
        clearFilter(){
            this.selectStatus = "0";
        },
        open(e){
            this.cardApplic = e;
            this.showModal = true;
        },
        changeTabs(){
            localStorage.setItem("ClientPaymentscheckbox", JSON.stringify(this.othertabs));
            localStorage.setItem("resultClientPayments", this.othertabs.length)
        },
        changeTab(name, value){
            console.log('name', name + " - " + value);
            if(name == 'Result'){
                this.selectStatus = value;
            }
        },
        mutatePay(e){
           return mutatePayPayments(e)
        }
    },
    mounted(){
        this.othertabs = localStorage.getItem("ClientPaymentscheckbox") ? JSON.parse(localStorage.getItem("ClientPaymentscheckbox")) : [];
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.paymentAmount.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

                // По статусу
                .filter(item => {
                    return this.selectStatus == 0 || item.paymentStatus == this.selectStatus;
                })
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>