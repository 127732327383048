import { createApp, h } from 'vue'
import { ws } from '@/socket/websocket';
import App from './App.vue'
import Dashboard from '@/Dashboard';
import mitt from 'mitt';
import "@/eventkey.js";
import "@/onkeydown.js";
import "@/socket/wsMessageHandler.js"
const eventBus = mitt();
export const eventKey = mitt();
export const onkeydown = mitt();
export const eventB = mitt();
export default eventBus;
import { storeS } from "@/store.js";
// import { state } from "@/socket.js";
import { storeSip } from "@/telephony/telephony.js";
import { Start } from '@/API.js'
let apiService = new Start();
import i18n from './i18n'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import "@vueform/multiselect/themes/default.css";
// import store from "./state/store";
import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import Toaster from '@meforma/vue-toaster';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
import moment from 'moment'
import Vue3TouchEvents from "vue3-touch-events";  
moment.locale('uk');
import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
// import io from "socket.io-client";


// https://inside.skyservice.pro/node1/?EIO=3&transport=polling&t=OTDRBzS
// const socket = io("https://inside.skyservice.pro/", {
//   path: "/node1/",
//   transports: ['websocket', 'polling', 'flashsocket']

// });

// socket.on("connect", () => {
//     console.log('socket', socket.connected); // true
// });

// socket.on('datetime', (datetime) => {
//   console.log('datetime', datetime); 
// })



AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

// import io from "socket.io-client";


// export const eventBus = new createApp();

const globalOptions = {
  modules: {
    toolbar: 'full',
  },
  scrollingContainer: 'scrollPost',
  theme: 'bubble'
}

const app = createApp(Dashboard);
app.config.globalProperties.$moment=moment
app.config.globalProperties.eventKey = eventKey;
app.config.globalProperties.onkeydown = onkeydown;
app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.eventB = eventB;
QuillEditor.props.globalOptions.default = () => globalOptions

apiService.getStart().then(json => {
  if(json.status == "done"){
    setStartOptions(json.data).then(() => {
      app
        .use(storeS)
        // .use(state)
        .use(Toaster)
        .use(i18n)
        .use(VueApexCharts)
        .use(Donut)
        .use(BootstrapVue3)
        .component(VueFeather.type, VueFeather)
        .use(Maska)
        .use(Particles)
        .component('QuillEditor', QuillEditor)
        .use(Vue3TouchEvents)
        .use(router)
        .use(VueShepherdPlugin)
        .use(Dashboard).mount('#app')
    })
  } else {
    
    // let div = document.createElement('div');
    // div.innerHTML = '<h1>Произошла ошибка!</h1> <br /> <button style="border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186); border-style: solid border-width: 1px; padding: 1px 7px 2px; text-rendering: auto; color: initial; display: inline-block; text-align: start; margin: 0em; font: 400 11px system-ui" onclick="location=`https://cabinet.skyservice.online/`"> Вернуться в кабинет </button>'
    // div.style.textAlign = 'center'
    // document.querySelector('#app').style.display = 'grid';
    // document.querySelector('#app').style.justifyContent = 'center'
    // document.querySelector('#app').style.height = '100vh'
    // document.querySelector('#app').style.alignItems = 'center'

    // document.querySelector('#app').prepend(div)
    fuckStart()

  }
}).catch(function(err) {
 
    let div = document.createElement('div');
    let txtBtn = i18n.global.t('backToCompanies')
    div.innerHTML = '<h1>An error has occurred!</h1> <br /> <button style="border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186); border-style: solid border-width: 1px; padding: 1px 7px 2px; text-rendering: auto; color: initial; display: inline-block; text-align: start; margin: 0em; font: 400 11px system-ui" onclick="location=`https://cabinet.skyservice.online/`">' + txtBtn + '</button>'
    div.style.textAlign = 'center'
    document.querySelector('#app').style.display = 'grid';
    document.querySelector('#app').style.justifyContent = 'center'
    document.querySelector('#app').style.height = '100vh'
    document.querySelector('#app').style.alignItems = 'center'

    document.querySelector('#app').prepend(div)
  //Не удалось получить параметры для старта системы
  fuckStart();
});

let fuckStart = () => {
  failedStart.style.display = 'block';
}

let setStartOptions = json => {
  return new Promise((resolve, reject) => {

    try {
      // отримуємо к-сть повідомлень та альортів
      storeS.messages = json.settings.countMessages
      storeS.alerts = json.settings.countNotices
    } catch (err) {
      // 
    }

    try {
    // Получение прав доступа
      // storeS.perms = json.settings.perms;
      const newPerms = json.settings.perms;

      

      storeS.perms = newPerms
      // // Додавання нових властивостей до storeS.perms
      // storeS.perms[666] = false;
      // storeS.perms[1901] = true;
      // storeS.perms[1902] = true;
      // storeS.perms[1903] = true;
      // storeS.perms[1904] = true;
      // storeS.perms[1905] = true;
      // console.log("str", storeS.perms);
    } catch (err) {
      // 
    }

    try {
      // Получение lang
        storeS.lang = json.settings.lang;
        i18n.global.locale = json.settings.lang
        localStorage.setItem('lang', json.settings.lang)
      } catch (err) {
        // 
      }

    try{
      //получение валюты и курса
      storeS.currencyShop = json.settings.currency;
    } catch (err) {
      //
    }

    //отримання категорій бази знань
    try{
      storeS.KBCategories = json.knowledgeBaseCategories
    } catch (err) {

    }
    //отримання категорій бази знань

    //отримання категорій FAQ
      try{
        storeS.FAQCateg = json.faqCategories
      } catch (err) {

      }
    //отримання категорій FAQ

    try{
      //получение данных фоп и тов
      storeS.invoice.FOP = json.settings.FOP;
      storeS.invoice.TOV = json.settings.TOV;
    } catch (err) {
      //
    }

    try {
      //статус перерыва
      storeS.break = json.settings.break;
    } catch (err){
      //
    }

    try {
      // Получение настроек
      storeS.checks = json.settings.settingcheck;
    } catch (err) {
      // 
    }
    
    try {
      // Отримання проєктів
      storeS.developmentProjects = json.developmentProjects;
      for(var item in json.developmentProjects) {
        var developmentProject = json.developmentProjects[item]
        if(developmentProject.favorite == true){
          storeS.developmentFavoriteProjects.push(developmentProject)
        }
      } 
    } catch (err) {
      // 
    }

    try {
      // Получение данных
      // state.myConnect.login = json.settings.name;
      // state.myConnect.workerId = json.settings.userid;
      // state.myConnect.shift.shiftId = json.settings.shiftId;
      // state.myConnect.shift.statusShift = json.settings.statusshift;
      // state.myConnect.shift.openShift = json.settings.workerOpenShift;
      // state.myConnect.shift.closeShift = json.settings.workerCloseShift;
      // state.myConnect.break.breakId = json.settings.break.breakId;
      // state.myConnect.break.statusBreak = json.settings.break.statusBreak;
      resolve(); 
    } catch(err){
      reject(err);
    }

    try {
      // Получение данных
      // console.log('suda2', state)

      // storeS.usersATS = json.onlineWorkersSip
      
      storeS.usersWS = json.onlineWorkers
      resolve(); 
    } catch(err){
      reject(err);
    }

    try{
      //получение данных для sip
      storeSip.info.login = json.settings.uaATS.login;
      storeSip.info.password = json.settings.uaATS.password;
      storeSip.info.name = json.settings.name
      resolve(); 
    } catch(err){
      reject(err)
    }

    try{
      storeS.groups = json.groups;
    } catch(err){
      //
    }

    try{
      storeS.tags = json.tags;
    } catch(err){
      //
    }

    try{
      storeS.tagsFaq = json.tagsByFaqPosts;
    } catch(err){
      //
    }

    try{
      storeS.tagsLessons = json.tagsByLessons;
    } catch(err){
      //
    }

    try {
      // Получение данных
      storeS.userbase = json.settings;
    } catch (err) {
      // 
    }

    try{
      // фігня Бориса
      // if(json.settings.fullAccess === true) {
      //   localStorage.setItem("fullAccess", true)
      // }
    } catch (err){

    }

    try {
      // Получение данных
      storeS.workers = json.workers;
    } catch (err) {
      // 
    }

    try {
      // Получение данных
      storeS.providers = json.providers;
    } catch (err) {
      // 
    }

    try {
      // Получение данных
      storeS.dealines = json.dealines;
    } catch (err) {
      // 
    }
    
    try {
      // Получение данных
      storeS.productCategories = json.productCategories;
    } catch (err) {
      // 
    }

    try {
      // Получение данных
      storeS.folders = json.folders;
    } catch (err) {
      // 
    }

    try {
      storeS.menu = require("@/components/dashboard/menu.js")["menu"]();
      resolve(); 
    } catch(err){
      reject(err);
    }

    //Подключение ВебСокета
    ws();

  });

}
// perms
// 100 - заявки
// 101 - добавить заявку
// 102 - передать заявку
// 103 - удалить заявку
// 104 - взять в работу заявку
// 105 - изменить заявку
// 110 - презентации
// 111 - добавление презентации 
// 112 - взять в работу
// 113 - редактирование презентации
// 114 - передать презентацию
// 115 - удалить презентацию
// 200 - задачи
// 201 - добавить задачу
// 202 - взять в работу
// 203 - удалить задачу
// 204 - редактировать задачу
// 205 - отложить задачу
// 300 - тикеты
// 301 - добавить тикет
// 302 - взять в работу
// 303 - удалить тикет
// 304 - редактировать тикет
// 305 - добавить репорт
// 306 - удалить репорт
// 307 - назначение тикета
// 350 - проекты 
// 351 - добавить проект
// 352 - взять в работу проект
// 353 - удалить проект
// 354 - редактировать проект
// 370 - обновления
// 371 - добавить обновление
// 372 - изменить
// 373 - удалить
// 380 - api (docs)
// 381 - добавить
// 382 - редактировать
// 383 - удалить
// 400 - архив
// 401 - архив заявок
// 402 - архив задач
// 403 - архив тикетов
// 404 - архив смен
// 405 - архив смс
// 406 - архив проектов
// 410 - заметки 
// 411 - добавление
// 412 - редактирование
// 413 - удаление
// 420 - календарь
// 421 - добавление события
// 422 - редактирование события
// 423 - удаление события
// 424 - редактировать доступ к типу события
// 500 - статистика
// 501 - по клиенту
// 502 - по поддержке
// 503 - по отделу разработки
// 504 - по сотрудникам
// 505 - статистика штрафов
// 506 - смены
// 507 - опоздания
// 508 - перерывы
// 510 - услуги
// 511 - добавить услугу
// 512 - редактировать услугу
// 513 - удалить услугу
// 520 - раздел отгулы/отпуски/вы
// 521 - подтверждение и отмена заявки на выходной
// 522 - удаление заявки на отпуск
// 610 - сотрудники
// 620 - команда
// 621 - добавить команду
// 622 - изменить команду
// 623 - удалить команду
// 624 - добавить юзера в команду
// 700 - сотрудники
// 701 - добавить сотрудника
// 702 - изменить права доступа сотруднику
// 703 - изменить сотрудника
// 704 - удалить сотрудника
// 705 - добавить юзера в команду
// 706 - добавить скилл
// 707 - добавление штрафов
// 708 - редагувати доступ по IP (відкриття і закриття зміни)
// 709 - додавання та оновлення інструкції
// 800 - Общая информация
// 801 - База знаний 
// 802 - добавить урок и категорию
// 803 - редактировать и удалить
// 900 - цены и калькулятор
// 950 - файловий менеджер
// 951 - створювати папки
// 952 - завантажувати файли
// 1000 - faq
// 1001 - добавить вопрос и ответ
// 1002 - удалить 
// 1003 - редактировать faq
// 1100 - ярлыки ответов
// 1101 - добавить ярлык
// 1102 - изменить ярлык
// 1103 - удалить ярлык
// 1104 - редактирование чужого ярлыка (в теории)
// 1200 - магазин
// 1300 - товары
// 1301 - добавить товар
// 1302 - изменить товар
// 1303 - удалить товар
// 1304 - відображення ціни постачальника
// 1400 - категории
// 1401 - добавить категорию
// 1402 - изменить категорию
// 1403 - удалить категорию
// 1500 - поставщики
// 1501 - добавить поставщика
// 1502 - изменить поставщика
// 1503 - удалить поставщика
// 1600 - атрибуты
// 1601 - добавить атрибут
// 1602 - изменить атрибут
// 1603 - удалить атрибут
// 1700 - счета
// 1701 - добавить счет
// 1702 - изменить счет
// 1703 - удалить счет
// 1704 - подтвердить счет
// 1800 - аккаунты
// 1810 - все аккаунты
// 1820 - аккаунты на удаление
// 1821 - удалить аккаунт 
// 1830 - все компании 
// 1840 - компании на удаление
// 1841 - удалить компанию
// 1850 - все регистрации
// 1860 - не завершенные регистрации
// 1900 - Повідомлення
// 1901 - Створення повідомлення
// 1902 - Редагування повідомлення
// 1903 - Видалення повідомлення
// 1904 - Підтвердження повідомлення
// 1905 - Відправка повідомлення
// 2000 - редактировать все че захочешь) режим бога

// 2021 - Увімкнути нову версію
// 2022 - Змінити тип облікового запису
// 2023 - Відновлення ТТ 
// 2024 - Очищення статистики 
// 2025 - Копіювання даних (Франшиза) 
// 2026 - Копіювання даниз (всередині облікового запису між компаніями) 
// 2027 - Скидання пароля 
// 2028 - Режим синхронізації 
// 2029 - Режим мультитерміналу 
// 20210 - Додати(змінити) домен 
// 20211 - Змінити ліміт компаній'
// 20212 - Копіювати дані з компанії на іншу компанію (в межах акаунту або між ними)

// 2030 - Доступ до моніторингу
// 2031 - Адмін моніторингу

// 2040 - Синхронізувати чеки

// 001 - отображение инфоблоков;
// 002 - горячие клавиши
// 003 - фильтрация
// 004 - скрывать заявки, проекты, тикеты не связанные со мной
// 005 - скрывать заявки ожидающие удаления
// 006 - телефония
// 007 - поиск только по номеру клиента и номеру тикета
// 008 - поиск только сотрудников (имя, номер)
// 009 - записывать данные в localStorage
// 010 - уведомления в телеграм
// 011 - уведомления по заявкам
// 012 - уведомления по презентациям
// 013 - уведомления по задачи
// 014 - уведомления по тикеты
// 015 - уведомления по проекты
// 021 - звуковые оповещения під щось інше
// 022 - темная тема
// 023 - автоматическое включения темной темы
// 024 - пасхалочки от разработчиков
// 025 - открывать модалку после создания
// 026 - предлогать добавить операцию в календарь
// 027 - звуковые оповещения
// 028 - звуковые оповещения по заявкам
// 029 - звуковые оповещения по задачам
// 030 - звуковые оповещения по тикетам
// 031 - звуковые оповещения по презентациям
// 032 - звуковые оповещения по проектам
// 033 - звуковые оповещения по получению сообщений в тикетах и проектах

// 100 - оповещение
// 110 - заявки 
// 111 - новая заявка
// 112 - пропущений дзвінок
// 113 - скасування заявки
// 114 - передача заявки
// 120 - відвали
// 121 - додавання відвалу
// 122 - передача відвалу
// 130 - презентації
// 131 - додавання презентацій
// 132 - скасування презентації
// 133 - передача презентації
// 140 - завдання
// 141 - додавання нового завдання
// 142 - передача завдання
// 143 - підтвердження завдання
// 144 - скасування завдання
// 150 - тікети
// 151 - створення тікета
// 152 - перевідкривання тікету
// 153 - назначення тікету за вами
// 154 - доданий тікет взяли в роботу
// 155 - до тікету додано рішення
// 156 - закриття тікету
// 157 - отримувати повідомлення по всім тікетам
// 158 - отримувати повідомлення тільки по тікетам, до яких назначений я

// 160 - проекти
// 161 - отримувати повідомлення по всім тікетам
// 162 - отримувати повідомлення тільки по тікетам, до яких назначений я

// 170 - перерви
// 171 - відкриття/закриття перерви

// 180 - зміни
// 181 - відкриття/закриття зміни не з того IP

// 190 - відгули
// 191 - запит на відпустку або вихідний







// tour
// 10 - перший старт
// 11 - дашборд
// заявки
// що таке інформаційні блоки
// 100 - заявки
// 101 - додати заявки
// як створити заявку,рахунок, завдання, тикет, проект, рахунок
// що таке інформаційні блоки
// як змінити ліміт строк у таблиці
// як знайти потрібний номер у таблиці, або тикет за описом
// як сформувати таблицю за певним періодом
// де я можу вказати потрібний фільтр?
